import { supabase } from '../lib/supabase'

const REMINDERS_TABLE_NAME = 'reminders'

export async function createReminder(profileId: string) {
  const { data } = await supabase
    .from(REMINDERS_TABLE_NAME)
    .insert({ profile_id: profileId })
    .throwOnError()

  return data
}

export async function getReminders(profileId: string) {
  const { data } = await supabase
    .from(REMINDERS_TABLE_NAME)
    .select('*')
    .eq('profile_id', profileId)
    .throwOnError()
    .maybeSingle()

  return data
}

export async function deleteReminder(profileId: string) {
  const { data } = await supabase
    .from(REMINDERS_TABLE_NAME)
    .delete()
    .eq('profile_id', profileId)
    .throwOnError()

  return data
}