export function RxMgmtModalTutorialComprehensive() {
  return (
    <div className="modal-body pt-0">
      {/* title is derived from the serviceName and subscriptionTier props passed to the HeaderWithTutorial component */}
      <p>
        First be sure that you have gone through and added any information in your
        Starter Healthcare Visit as well as the Advanced before using the
        Comprehensive application.
      </p>
      <p>
        The Comprehensive Healthcare Visit tier looks very similar to the Starter and
        Advanced tier with some important additions.
      </p>
      <p>
        In addition to all the input that you have done, the Comprehensive has a section
        where you can put in any notes that your healthcare professional has given you.
        That individual may have also provided you with recommendations; there is an
        area where you can document this.
      </p>
      <p>
        YourHealth Life Insights adds two buttons to query for more information and
        insights. One is what you used in YourHealth Advanced – Are there medical
        treatments or issues that could affect my current treatment? If you added
        additional information, you may wish to query using this button again. The
        second button – Are there things I can be doing to improve my health – which
        can give you tips or suggestions based on the information that you have
        provided.
      </p>
      <p>
        As always consult your health professional with any results that you obtain or
        questions that you might have.
      </p>
      <div style={{ maxWidth: 600 }}>
        <img
          src="/tutorials/healthcare-visits/comprehensive/hcv1.png"
          alt="Healthcare Visits Comprehensive Form"
          className="img-fluid shadow rounded-sm"
        />
      </div>
    </div>
  )
}
