import { useQuery } from '@tanstack/react-query'
import { createCheckoutSession } from '../../supabase-api/stripe'
import { getCurrentUser } from '../../supabase-api/current_user'
import { useHistory } from 'react-router-dom'

export default function Checkout() {
    const params = new URLSearchParams(window.location.search)
    const price_id = params.get('price_id')
    const coupon_id = params.get('coupon_id')
    const history = useHistory()

    const { data: currentUser, isLoading: isCurrentUserLoading } = useQuery({
        queryKey: ['current_user'],
        queryFn: () => getCurrentUser(),
    })

    const { data: checkoutSession } = useQuery({
        queryKey: ['stripe_checkout', price_id],
        queryFn: () => createCheckoutSession(price_id!, coupon_id || undefined),
        retry: false,
        enabled: !!price_id && !!currentUser
    })


    console.log("checkoutSession", checkoutSession)

    // if (isCurrentUserLoading) {
    //     return null
    // }

    if (!currentUser && !isCurrentUserLoading) {
        history.replace('/auth/login')
        // history.replace(`/auth/login?redirect_url=${encodeURIComponent(window.location.href)}`)

    }

    if (checkoutSession) {
        window.location.href = checkoutSession.url
    } 
    // else { // sorry...
    //     return (<div style={{ width: '100vw', height: '100dvh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Error creating checkout session. We've logged this and will investigate.</div>)
    // }

    return (
        <div style={{ width: '100vw', height: '100dvh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
                className="spinner-border mr-2"
                role="status"
                style={{
                    width: 20,
                    height: 20,
                    marginRight: 10
                }}
            />
            <span>Loading...</span>
        </div>
    )
}
