import classnames from "classnames";
import { PropTypes } from "prop-types";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from 'react-redux';
import { Link, NavLink as NavLinkRRD, useHistory, useLocation } from "react-router-dom";
import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
} from "reactstrap";
import { getActiveSubscriptions } from '../../supabase-api/active_subscriptions'
import { useQuery } from '@tanstack/react-query'
import { isSubscriptionTierAvailable } from '../../utils/subscriptionTierAvailablityCheck'

function Sidebar({ profile, toggleSidenav, sidenavOpen, routes, logo, rtlActive }) {
  const [state, setState] = React.useState({});
  let history = useHistory();
  let location = useLocation();

  const { data: activeSubscriptions, isLoading: isLoadingActiveSubscriptions } = useQuery({
    queryKey: ['user_subscriptions'],
    queryFn: () => getActiveSubscriptions(profile.user.id),
    retry: false,
    enabled: !!profile.user.id
  })

  React.useEffect(() => {
    setState(getCollapseStates(routes));
  }, []);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("nav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };

  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      let isLinkVisible = true;

      if (prop.redirect || prop.hidden) {
        isLinkVisible = false;
      }

      if (prop.admin && !profile.isAdmin) {
        isLinkVisible = false;
      }

      if (prop.beta && !profile.isBeta) {
        isLinkVisible = false;
      }

      if (prop.admin && profile.isAdmin) {
        isLinkVisible = true;
      }

      if (prop.beta && profile.isBeta) {
        isLinkVisible = true;
      }

      if (!isLinkVisible) {
        return null;
      }

      if (prop.subscription) {
      }

      const isTierAvailable = prop.subscription && isSubscriptionTierAvailable(activeSubscriptions?.map(sub => sub.products?.name), prop.subscription)

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href=""
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              className={classnames({
                active: getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                  {prop.comingSoon &&
                    <span
                      style={{
                        fontSize: 10,
                        position: 'absolute',
                        marginTop: 40,
                        marginLeft: 30,
                        // top: 0
                        // top: 0,
                        // right: 0,
                        // transform: 'translate(100%, -100%)',
                        backgroundColor: '#f6f9fc',
                        padding: '2px 5px',
                        borderRadius: 65
                      }}
                      className="nav-link-text"
                    >
                      Coming Soon
                    </span>
                  }
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span
                    className="sidenav-normal"
                    style={{
                      opacity: prop.subscription ? (isTierAvailable ? 1 : 0.5) : 1
                    }}
                  >
                    {prop.name}
                  </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            // take to page that has a link to 1yourhealth.com/subscriptions
            // to={prop.name === 'Comprehensive' ? 'https://dev.1yourhealth.com/subscriptions' : prop.layout + prop.path}
            // YOURHEALTH FAMILY PLAN SECTION
            // UPDATE LEARN MORE SECTION TO INCLUDE GOOGLE DOCS FROM BILL
            activeClassName=""
            onClick={closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>

                {/* subscription available check here */}
                {/* <span className={classnames("sidenav-normal", prop.name === 'Starter' || prop.name === 'Advanced' && 'subscription-available')}> {prop.name} </span> */}
                {/* <span className={classnames("sidenav-normal", prop.name === 'Comprehensive' && 'subscription-unavailable')}> {prop.name} </span> */}
                <span
                  className={classnames("sidenav-normal")}
                  style={{
                    opacity: prop.subscription ? (isTierAvailable ? 1 : 0.75) : 1
                  }}
                >
                  {prop.name} {prop.subscription && !isTierAvailable && <span style={{ fontSize: 9, backgroundColor: '#ebe8e8', padding: '2px 5px', marginLeft: 5, borderRadius: 65 }}>Upgrade</span>}
                </span>

              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>{createLinks(routes)}</Nav>
          {/* <h6 className="navbar-heading p-0 text-muted">
            <span className="docs-normal">Home</span>
          </h6>
          <hr className="my-3" />
          <h6 className="navbar-heading p-0 text-muted">
            <span className="docs-normal">Free Essential Application</span>
          </h6> */}
          {/* <Nav className="" navbar>
            <NavItem>
              <NavLink href={process.env.REACT_APP_ADMIN_URL}>
                <i className="ni ni-settings text-primary" />
                <span className="nav-link-text">Admin Portal</span>
              </NavLink>
            </NavItem>
          </Nav> */}
          {/* <hr className="my-3" />
          <h6 className="navbar-heading p-0 text-muted">
            <span className="docs-normal">Subscriptions</span>
          </h6> */}
          {/* <hr className="my-3" /> */}
          {/* 
          <h6 className="navbar-heading p-0 text-muted">
            <span className="docs-normal">Subscriptions</span>
            <span  className="docs-normal">YourHealth Life Subscription Applications</span>
          </h6>
          <hr className="my-3" />
          <SubscriptionAppNav /> */}

          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="/admin/family">
                <i className="fa-solid fa-people-group text-primary" />
                <span className="nav-link-text">YourHealth Family</span>
                <span
                  style={{
                    fontSize: 10,
                    position: 'absolute',
                    marginTop: 40,
                    marginLeft: 30,
                    // top: 0
                    // top: 0,
                    // right: 0,
                    // transform: 'translate(100%, -100%)',
                    backgroundColor: '#f6f9fc',
                    padding: '2px 5px',
                    borderRadius: 65
                  }}
                  className="nav-link-text"
                >
                  Coming Soon
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <hr className="my-3" />
          <h6 className="navbar-heading p-0 text-muted">
            <span className="docs-normal">Learn More</span>
          </h6>
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink
                href="#"
                onClick={(e) => {
                  // Prevent default
                  e.preventDefault();

                  // Send to account
                  history.push('/admin/home?tutorial=getting-started');
                }}
              >
                <i className="ni ni-spaceship" />
                <span className="nav-link-text">Getting Started With YourHealth Essential </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                onClick={(e) => {
                  // Prevent default
                  e.preventDefault();

                  // Send to account
                  history.push('/admin/home?tutorial=yh-life');
                }}
              >
                <i className="fa-solid fa-hand-holding-heart"></i>
                <span className="nav-link-text">Getting Started With YourHealth Life</span>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                href="/admin/home"
                onClick={(e) => {
                  // Prevent default
                  e.preventDefault();

                  // Send to account
                  history.push('/admin/home?tutorial=getting-started');
                }}
              >
                <i className="ni ni-spaceship" />
                <span className="nav-link-text">Getting Started</span>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                href="/admin/home"
                onClick={(e) => {
                  // Prevent default
                  e.preventDefault();

                  // Send to account
                  history.push('/admin/home?tutorial=frequently-asked-questions');
                }}
              >
                <i className="ni ni-collection" />
                <span className="nav-link-text">Frequently Asked Questions</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/admin/home"
                onClick={(e) => {
                  // Prevent default
                  e.preventDefault();

                  // Send to account
                  history.push('/admin/home?tutorial=add-to-device');
                }}
              >
                <i className="ni ni-app" />
                <span className="nav-link-text">Add to Device</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/admin/home"
                onClick={(e) => {
                  // Prevent default
                  e.preventDefault();

                  // Send to account
                  history.push('/admin/home?tutorial=send-qr-code');
                }}
              >
                <i className="ni ni-send" />
                <span className="nav-link-text">Send QR Code</span>
              </NavLink>
            </NavItem>
          </Nav>

          {profile.isAdmin && (
            <>
              <hr className="my-3" />
              <h6 className="navbar-heading p-0 text-muted">
                <span className="docs-normal">Admin</span>
              </h6>
              <Nav className="" navbar>
                <NavItem>
                  <NavLink href={process.env.REACT_APP_ADMIN_URL}>
                    <i className="ni ni-settings text-primary" />
                    <span className="nav-link-text">Admin Portal</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </>
          )}
        </Collapse>
      </div>
    </div>
  );

  return (
    <Navbar
      id="yh-navbar"
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
        (rtlActive ? "" : "fixed-left") +
        (profile.welcome.complete ? "" : " d-none")
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => { },
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

// Connect to store
const ConnectedSidebar = connect(state => ({
  profile: state.profile
}))(Sidebar);

export default ConnectedSidebar;
