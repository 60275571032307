import { ComingSoon } from "../shared/ComingSoon"

export default function YourHealthFamily() {
    return <ComingSoon
        title="YourHealth Family Plan"
        description="With dinner dates, soccer games, recitals, meal prep being a family can be overwhelming. Yet the most critical element of a family, the health and wellness of each of its members lacks a comprehensive way of understanding and managing everyone. The Family Plan solves these problems. One plan, with selected application services gives you an overview of every family member, with better understanding and protection for each individual."
    />

}

