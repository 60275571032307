import _ from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from 'react-phone-number-input/input';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import YourHealth from '../../../api';
import CardEditHeader from "../../../components/Headers/CardEditHeader.js";
import QrCodeStylingComponent from "../../../components/QRCode";
import { LOAD_CONTACTS } from '../../../store/actions';
import { supabase } from '../../../lib/supabase';
import { getCardUrl } from './utils';
import { notifyError, notifySuccess } from '../../../utils/notify';

const initialFormState = {
  type: 'NEIGHBOR',
  isChampion: false,
  email: '',
  phone: '',
  first_name: '',
  last_name: '',
  profileContactsId: ''
}

const initialSelectedState = {
  id: '',
  type: 'NEIGHBOR',
  isChampion: false,
  email: '',
  phone: '',
  first_name: '',
  last_name: '',
  profileContactsId: ''
}

const initialSmsState = {
  name: '',
  phone: ''
}

function NeighborCard({ profile, medical, dispatch }) {
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [contactModal, setcontactModal] = React.useState(false);
  const [qrModal, setqrModal] = React.useState(false);
  const [showQRCode, setShowQRCode] = React.useState(false);
  const [qrCodeSize, setqrCodeSize] = useState("2x2");
  const [smsModal, setsmsModal] = React.useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [selectedData, setSelectedData] = useState(initialSelectedState);
  const [notesData, setNotesData] = useState("");
  const [contactTypes, setContactTypes] = useState([])
  const [cardUrl, setCardUrl] = useState(null)

  // QR container
  const qrRef = useRef(null);
  let history = useHistory();

  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  async function init() {
    // Set format data
    setFormData({
      ...formData,
      'profileContactsId': profile.user.id
    });

    // Set notes data
    setNotesData(profile.user.neighbor_notes);

    const cardUrl = await getCardUrl(profile.user.id, 'neighbor');
    setCardUrl(cardUrl);

    await getContactTypes()
    await getContacts(profile.user.id);
  }

  async function getContactTypes() {
    const { data, error } = await supabase
      .from('contact_type')
      .select('*')

    if (error) {
      console.error('error getting contact types', error)
    }

    setContactTypes(data);
  }

  async function getContacts(profileId) {
    let { data, error } = await supabase
      .from('contact')
      .select('*, contact_type(*)')
      .eq('profile_id', profileId)

    if (error) {
      console.error('error getting contacts', error)
    }

    dispatch({
      type: LOAD_CONTACTS,
      payload: data
    });
  }

  // Save contact
  async function saveContact() {
    if (!formData.first_name || !formData.last_name) return;

    // Track event
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      if (window.posthog) {
        window.posthog.capture('Updated Data', {
          category: "Essential Care Cards",
          label: "Neighbor",
          $set: {
            email: profile.user.email,
          }
        });
      }
      
      // Mark change to profile
      YourHealth.api.email.tag({
        email: profile.user.email,
        list: "all_members",
        tag: "Updated_Profile"
      });
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      // Mark change to profile
      YourHealth.api.email.tag({
        email: profile.user.email,
        list: "all_members",
        tag: "Updated_Profile"
      });
    }

    let newContact = JSON.parse(JSON.stringify(formData));

    const { data, error } = await supabase
      .from('contact')
      .insert(
        {
          email: newContact.email,
          phone: newContact.phone,
          first_name: newContact.first_name,
          last_name: newContact.last_name,
          contact_type_id: contactTypes.find(type => type.name === 'NEIGHBOR').id,
          is_champion: true,
        },
      )

    if (error) {
      console.error('error adding neighbor', error)
      notifyError('Error adding neighbor')
      return
    }

    notifySuccess('Neighbor added successfully')

    // Update contacts
    await getContacts(profile.user.id);
  }

  async function modifyContact(contact) {
    delete contact.createdAt;

    const { data, error } = await supabase
      .from('contact')
      .update(
        {
          email: contact.email,
          phone: contact.phone,
          first_name: contact.first_name,
          last_name: contact.last_name,
          contact_type_id: contact.type,
          is_champion: contact.is_champion,
        },
      )
      .eq('id', contact.id)

    if (error) {
      console.error('error updating neighbor', error)
      notifyError('Error updating neighbor')
      return
    }

    notifySuccess('Neighbor updated successfully')

    await getContacts(profile.user.id);
  }

  const formatPhoneNumber = (str) => {
    if (!str) return ''

    //Filter only numbers from the input
    let cleaned = ('' + str.substr(2)).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
  };

  // Update notes
  async function updateNotes() {
    const { data, error } = await supabase
      .from('profile')
      .update({
        neighbor_notes: notesData,
      })
      .eq('id', profile.user.id)

    if (error) {
      console.error('error updating notes for profile', error)
      notifyError('Error updating notes')
      return
    }

    notifySuccess('Notes updated successfully')

    // Sync profile
    YourHealth.api.profile.sync({ profile, dispatch });
  }

  const NEIGHBOR_CONTACT_TYPES = ['NEIGHBOR']

  return (
    <>
      <CardEditHeader
        image="hero-neighbor"
        profile={profile}
        title="Neighbor"
        description="Coordinated with your Care Champion to handle tasks around the home when you can't."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-sm-1 order-xl-2" xl="4">
            <Card style={(showQRCode) ?
              {
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                zIndex: 1000,
                transition: 'all 1s ease-out'
              }
              :
              {
                transition: 'all 1s ease-out'
              }
            }>
              {showQRCode &&
                <CardHeader style={(showQRCode && profile.isMobile) ?
                  {
                    height: "calc(100vh - 220px)",
                    backgroundColor: "#F6BB41",
                    paddingTop: 50
                  }
                  :
                  {
                    backgroundColor: "#F6BB41"
                  }
                }>
                  <div style={{
                    width: '100%',
                    textAlign: 'center'
                  }}>
                    <h3 className="text-center mt-2 mb-3 px-3" style={{ color: "#FFFFFF" }}>
                      This is the QR code that contains the information that you would provide to your Neighbor.
                    </h3>
                    <hr className="my-4" />
                    <QrCodeStylingComponent
                      data={cardUrl}
                      // data={(profile.user.neighborCardUrl) ? ((profile.user.version && profile.user.version.length > 0) ? `${profile.user.neighborCardUrl + '#' + profile.user.version}` : profile.user.neighborCardUrl) : ''}
                      bgColor="white"
                      fgColor="#45296F"
                      eyeColor="#F6BB41"
                      dotColor="#F6BB41"
                      centerImageSrc="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/logo.md.png"
                    ></QrCodeStylingComponent>
                    <h1 className="text-center mt-4" style={{ color: "#FFFFFF" }}>View My Neighbor</h1>
                    <h1 className="text-center mt-1" style={{ color: "#FFFFFF" }}>Instructions Here</h1>
                  </div>
                </CardHeader>
              }
              {!profile.isMobile &&
                <CardHeader style={{ backgroundColor: "#F6BB41" }}>
                  <div style={{
                    width: '100%',
                    textAlign: 'center'
                  }}>
                    <h3 className="text-center mt-2 mb-3 px-3" style={{ color: "#FFFFFF" }}>
                      This is the QR code that contains the information that you would provide to your Neighbor.
                    </h3>
                    <hr className="my-4" />
                    <QrCodeStylingComponent
                      data={cardUrl}
                      //data={(profile.user.neighborCardUrl) ? profile.user.neighborCardUrl : ''}
                      // data={(profile.user.neighborCardUrl) ? ((profile.user.version && profile.user.version.length > 0) ? `${profile.user.neighborCardUrl + '#' + profile.user.version}` : profile.user.neighborCardUrl) : ''}
                      bgColor="white"
                      fgColor="#45296F"
                      eyeColor="#F6BB41"
                      dotColor="#F6BB41"
                      centerImageSrc="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/logo.md.png"
                    ></QrCodeStylingComponent>
                    <h1 className="text-center mt-4" style={{ color: "#FFFFFF" }}>View My Neighbor</h1>
                    <h1 className="text-center mt-1" style={{ color: "#FFFFFF" }}>Instructions Here</h1>
                  </div>
                </CardHeader>
              }
              {profile.isMobile ?
                <CardBody style={(showQRCode) ?
                  {
                    height: 220
                  }
                  :
                  {}
                }>
                  <Row className="mb-2">
                    <Button block color="primary" size="lg" type="button"
                      onClick={() => {
                        // Load QR Modal
                        setShowQRCode(!showQRCode);
                      }}
                    >
                      {(showQRCode) ? 'Hide QR Code' : 'Show QR Code'}
                    </Button>
                  </Row>
                  <Row className="mb-2">
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => {
                        // Load QR Modal
                        setqrModal(true);
                      }}
                    >
                      Print QR Code
                    </Button>
                  </Row>
                  <Row className="mb-2">
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => {
                        setsmsModal(true);
                      }}
                    >
                      Send QR Code
                    </Button>
                  </Row>
                  {cardUrl &&
                    <Row className="mb-2">
                      <Button
                        block
                        color="secondary"
                        size="lg"
                        type="button"
                        href={cardUrl}
                        target="_blank"
                      >
                        View Card Details
                      </Button>
                    </Row>
                  }
                </CardBody>
                :
                <CardBody>
                  <Row className="mb-2">
                    <Button block color="primary" size="lg" type="button"
                      onClick={() => {
                        // Load QR Modal
                        setqrModal(true);
                      }}
                    >
                      Print QR Code
                    </Button>
                  </Row>
                  <Row className="mb-2">
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => {
                        setsmsModal(true);
                      }}
                    >
                      Send QR Code
                    </Button>
                  </Row>
                  {cardUrl &&
                    <Row className="mb-2">
                      <Button
                        block
                        color="secondary"
                        size="lg"
                        type="button"
                        href={cardUrl}
                        target="_blank"
                      >
                        View Card Details
                      </Button>
                    </Row>
                  }
                </CardBody>
              }
            </Card>
          </Col>
          <Col className="order-sm-2 order-xl-1" xl="8">
            <Card id="qr-code-print-instructions">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Printing Your QR Codes</h3>
                  </Col>
                  <Col className="text-right" xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <div className="col mb-4 card-text">
                    <p>It is easy to print out the QR codes for your neighbor information. Once you have filled out the information you want for your Essential Care Card (ECC), Scroll down until you see the yellow QR code for your neighbor information; click on it. Just below you will see “Print QR Code” and by clicking on that button, you will be taken to the familiar print dialogue that you use for other documents. You can have one on your refrigerator, in your car’s glove compartment, in your purse or wallet or insurance care so you are always prepared.</p>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">My Neighbors</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      href=""
                      id="tooltip969372949"
                      onClick={(e) => {
                        // Stop default behavior
                        e.preventDefault();

                        // Open Modal
                        setdefaultModal(true);
                      }}
                      size="md"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">Add</span>
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip969372949">
                      Add Neighbor
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Contact</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {medical.contacts.filter(contact => NEIGHBOR_CONTACT_TYPES.includes(contact.contact_type.name)).map((contact, index) => (
                    <tr key={index}>
                      <td className="table-user">
                        <img
                          alt="..."
                          className="avatar rounded-circle mr-3"
                          src="/assets/img/profiles/user.png"
                        />
                        <a
                          className="font-weight-bold"
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedData({
                              id: contact.id,
                              type: contact.type,
                              email: contact.email,
                              phone: contact.phone,
                              first_name: contact.first_name,
                              last_name: contact.last_name,
                              profileContactsId: formData.profileContactsId
                            });

                            // Open Modal
                            setcontactModal(true);
                          }}
                        >
                          <b>{contact.first_name} {contact.last_name}</b>
                        </a>
                      </td>
                      <td>
                        <a href={`tel:${contact.phone}`}>{formatPhoneNumber(contact.phone)}</a>
                      </td>
                      <td>
                        {contact.email &&
                          <a href={`mailto:${contact.email}`}>
                            <Button
                              color="primary"
                              href=""
                              size="sm"
                            >
                              Send Email
                            </Button>
                          </a>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add Notes</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Neighbor Notes</label>
                          <Input
                            placeholder=""
                            value={notesData ?? ''}
                            rows="7"
                            type="textarea"
                            onChange={e => setNotesData(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="secondary"
                          style={{
                            height: (profile.isMobile) ? '85px' : '100%'
                          }}
                          href=""
                          onClick={(e) => {
                            // Prevent default
                            e.preventDefault();

                            // Send to account
                            history.push('/admin/home');
                          }}
                          size="xl"
                        >
                          Return to Profile
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '85px' : '100%'
                          }}
                          href=""
                          onClick={updateNotes}
                          size="xl"
                        >
                          Update Neighbor Notes
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Add New Neighbor
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      First Name
                    </label>
                    <Input
                      id="input-first-name"
                      placeholder=""
                      type="text"
                      value={formData.first_name}
                      onChange={e => setFormData({ ...formData, 'first_name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-last-name"
                    >
                      Last Name
                    </label>
                    <Input
                      id="input-last-name"
                      placeholder=""
                      type="text"
                      value={formData.last_name}
                      onChange={e => setFormData({ ...formData, 'last_name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Email Address
                    </label>
                    <Input
                      id="input-email"
                      placeholder=""
                      type="email"
                      value={formData.email}
                      onChange={e => setFormData({ ...formData, 'email': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-phone"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      id="input-phone"
                      className="form-control"
                      defaultCountry="US"
                      value={formData.phone}
                      onChange={phone => {
                        setFormData({ ...formData, 'phone': phone })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={() => {
              saveContact(formData);

              // Close modal
              setdefaultModal(false);
            }}
            size="xl"
          >
            Add Neighbor
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={smsModal}
        toggle={() => setsmsModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Send Essential Care Card
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setsmsModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Contact</th>
                <th>Consent</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {_.filter(medical.contacts, function (c) { return c.has_consent; }).map((contact, index) => (
                <tr key={index}>
                  <td className="table-user">
                    <img
                      alt="..."
                      className="avatar rounded-circle mr-3"
                      src="/assets/img/profiles/user.png"
                    />
                    <b>{contact.first_name} {contact.last_name}</b>
                  </td>
                  <td>
                    {contact.has_consent &&
                      <a
                        className="table-action"
                        href="#"
                        id="tooltip601065234"
                      >
                        <i className="ni ni-check-bold" />
                      </a>
                    }
                  </td>
                  <td className="table-actions">
                    <Button
                      color="primary"
                      href=""
                      onClick={() => {
                        // Generate QR code
                        YourHealth.api.ecc.generate({
                          environment: "prod",
                          card: "medical",
                          username: profile.user.username
                        });

                        // Send SMS
                        YourHealth.api.ecc.send({
                          sender: {
                            name: (profile.user) ? `${profile.user.first_name} ${profile.user.last_name}` : ''
                          },
                          recipient: {
                            name: `${contact.first_name} ${contact.last_name}`,
                            phone: `${contact.phone}`
                          },
                          ecc: {
                            image: `https://storage.googleapis.com/yourhealth-cdn/ecc/prod/${profile.user.username}/medical.png`,
                            url: cardUrl,
                            //url: profile.user.neighborCardUrl
                            // url: (profile.user.version && profile.user.version.length > 0) ? `${profile.user.neighborCardUrl + '#' + profile.user.version}` : profile.user.neighborCardUrl
                          }
                        });

                        // Close modal
                        setsmsModal(false);
                      }}
                      size="sm"
                    >
                      Send Care Card
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="modal-footer" style={{
          paddingTop: 0,
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem'
        }}>
          <p>Don't see your contact? Learn about <a href="/admin/home?tutorial=send-qr-code">sending QR codes vis SMS</a>.</p>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={qrModal}
        toggle={() => setqrModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Print QR Code
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setqrModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="qrCodeSize"
              >
                Select Size
              </label>
              <Input
                id="qrCodeSize"
                type="select"
                value={qrCodeSize}
                onChange={(evt) => {
                  // Set QR Code Size
                  setqrCodeSize(evt.target.value);
                }}
              >
                <option value="1x1">1" x 1" (Sticker)</option>
                <option value="2x2">2" x 2" (Wallet / Purse)</option>
                <option value="3x3">3" x 3" (Display)</option>
              </Input>
            </FormGroup>
          </Form>
          <div ref={qrRef} style={{
            width: `${parseInt(qrCodeSize[0])}in`,
            height: `${parseInt(qrCodeSize[0]) * 1.5}in`,
            textAlign: 'center',
            backgroundColor: '#F6BB41',
            paddingTop: `${parseInt(qrCodeSize[0]) * 10}px`,
            borderRadius: '15px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
            <style type="text/css" media="print">{`
              @page {
                margin: 2in 2in 2in 2in;
                backgroundColor: #FFFFFF;
              }
            `}</style>
            <QrCodeStylingComponent
              data={cardUrl}
              // data={(profile.user.neighborCardUrl) ? ((profile.user.version && profile.user.version.length > 0) ? `${profile.user.neighborCardUrl + '#' + profile.user.version}` : profile.user.neighborCardUrl) : ''}
              width={75 * parseInt(qrCodeSize[0])}
              height={75 * parseInt(qrCodeSize[0])}
              bgColor="white"
              fgColor="#45296F"
              eyeColor="#F6BB41"
              dotColor="#F6BB41"
              centerImageSrc="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/logo.md.png"
            ></QrCodeStylingComponent>
            <h4 className="text-center" style={{
              marginTop: `${parseInt(qrCodeSize[0]) * 0.75}em`,
              fontSize: `${parseInt(qrCodeSize[0]) * 50}%`,
              color: "#FFFFFF"
            }}>View My Neighbor</h4>
            <h4 className="text-center" style={{
              marginTop: `${parseInt(qrCodeSize[0]) * 0.125}em`,
              fontSize: `${parseInt(qrCodeSize[0]) * 50}%`,
              color: "#FFFFFF"
            }}>Instructions Here</h4>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={() => {
              // Update contact
              //console.log("Adding new doctor contact: ", formData);
            }}
            size="xl"
          >
            <ReactToPrint
              trigger={() => <span>Print</span>}
              content={() => qrRef.current}
            />
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={contactModal}
        toggle={() => setcontactModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Contact
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setcontactModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      First Name
                    </label>
                    <Input
                      id="input-first-name"
                      placeholder=""
                      type="text"
                      value={selectedData.first_name}
                      onChange={e => setSelectedData({ ...selectedData, 'first_name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-last-name"
                    >
                      Last Name
                    </label>
                    <Input
                      id="input-last-name"
                      placeholder=""
                      type="text"
                      value={selectedData.last_name}
                      onChange={e => setSelectedData({ ...selectedData, 'last_name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Email Address
                    </label>
                    <Input
                      id="input-email"
                      placeholder=""
                      type="email"
                      value={selectedData.email}
                      onChange={e => setSelectedData({ ...selectedData, 'email': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-phone"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      id="input-phone"
                      className="form-control"
                      defaultCountry="US"
                      value={selectedData.phone}
                      onChange={phone => {
                        setSelectedData({ ...selectedData, 'phone': phone })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={() => {
              modifyContact(selectedData);

              // Close modal
              setcontactModal(false);
            }}
            size="xl"
          >
            Update Contact
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedNeighborCard = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(NeighborCard);

export default ConnectedNeighborCard;
