import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import YourHealth from '../../../api';
import MedicalAdminHeader from "../../../components/Headers/MedicalAdminHeader.js";
import {
  LOAD_CONDITIONS
} from '../../../store/actions';
import { supabase } from '../../../lib/supabase';
import { notifyError, notifySuccess } from '../../../utils/notify';

const initialFormState = {
  name: ''
}

const initialSelectedState = {
  id: '',
  name: '',
  profileConditionsId: ''
}

function Conditions({ profile, medical, dispatch }) {
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [addItem, setAddItem] = React.useState(false);
  const [activeNav, setActiveNav] = React.useState(1);
  const [formData, setFormData] = useState(initialFormState);
  const [singleSelections, setSingleSelections] = useState([]);
  const [selectedData, setSelectedData] = useState(initialSelectedState);
  const [singleSelectedSelections, setSelectedSingleSelections] = useState([]);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  let history = useHistory();

  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  // Find conditions
  async function init() {
    // Set format data
    setFormData({
      ...formData,
      'profileConditionsId': profile.user.id
    });

    // Update conditions
    getConditions(profile.user.id);
  }

  // Validate on form data change
  useEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true;
    // Check if valid - first + last name
    if (formData.name != "") {
      setAddItem(true);
    } else {
      // Set save flag
      setAddItem(false);
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [formData]);

  async function getConditions(profileId) {
    let { data, error } = await supabase
      .from('condition')
      .select('*')
      .eq('profile_id', profileId)

    if (error) {
      console.error("Error fetching conditions: ", error);
    }

    dispatch({
      type: LOAD_CONDITIONS,
      payload: data
    });
  }

  async function saveCondition() {
    if (!formData.name) return;

    // Track event
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      if (window.posthog) {
        // Track signup
        window.posthog.capture('Updated Data', {
          category: "Medical Information",
          label: "Conditions",
          $set: {
            email: profile.user.email,
          }
        });
      }
      
      // Mark change to profile
      YourHealth.api.email.tag({
        email: profile.user.email,
        list: "all_members",
        tag: "Updated_Profile"
      });
    }

    const { data, error } = await supabase
      .from('condition')
      .insert({
        name: formData.name,
      })

    if (error) {
      console.error("Error creating condition: ", error);
      notifyError("Error adding condition");
      return
    }

    notifySuccess("Condition added successfully");

    // Clear form data
    setFormData({
      ...initialFormState,
      'profileConditionsId': profile.user.id
    });

    // Clear selection data
    setSingleSelections([]);

    // Reset flag
    setAddItem(false);

    await getConditions(profile.user.id);
  }

  // Delete Condition
  async function removeCondition(conditionId) {
    const { error } = await supabase
      .from('condition')
      .delete()
      .eq('id', conditionId)

    if (error) {
      console.error("Error deleting condition: ", error);
      notifyError("Error deleting condition");
      return
    }

    notifySuccess("Condition deleted successfully");

    await getConditions(profile.user.id);
  }

  async function modifyCondition(condition) {
    const { data, error } = await supabase
      .from('condition')
      .update({ name: condition.name })
      .eq('id', condition.id)

    if (error) {
      console.error("Error updating condition: ", error);
      notifyError("Error updating condition");
      return
    }

    notifySuccess("Condition updated successfully");

    await getConditions(profile.user.id);
  }

  return (
    <>
      <MedicalAdminHeader name="Conditions" parentName="Medical Information" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add New Condition</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-condition"
                          >
                            Medical Condition (Required)
                          </label>
                          <Input
                            id="input-condition"
                            placeholder=""
                            type="text"
                            value={formData.name}
                            onChange={e => setFormData({ ...formData, 'name': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="secondary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          href=""
                          onClick={(e) => {
                            // Prevent default
                            e.preventDefault();

                            // Send to account
                            history.push('/admin/home');
                          }}
                          size="xl"
                        >
                          Return to Profile
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          onClick={saveCondition}
                          size="xl"
                          disabled={!addItem}
                        >
                          Add Condition
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">My Medical Conditions</h3>
                  </Col>
                  <Col className="text-right" xs="6">

                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Condition Name</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {medical.conditions.map((condition, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          className="font-weight-bold"
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            // Set selected data
                            setSelectedData({
                              id: condition.id,
                              name: condition.name,
                              profileConditionsId: formData.profileConditionsId
                            });

                            // Set modal selected
                            setSelectedSingleSelections([condition.name]);

                            // Open Modal
                            setdefaultModal(true);
                          }}
                        >
                          {condition.name}
                        </a>
                      </td>
                      <td className="table-actions">
                        <a
                          className="table-action"
                          href=""
                          id="tooltip564981685"
                          onClick={(e) => {
                            // Stop default behavior
                            e.preventDefault();

                            // Set selected data
                            setSelectedData({
                              id: condition.id,
                              name: condition.name,
                              profileConditionsId: formData.profileConditionsId
                            });

                            // Set modal selected
                            setSelectedSingleSelections([condition.name]);

                            // Open Modal
                            setdefaultModal(true);
                          }}
                        >
                          <i className="fas fa-user-edit" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip564981685">
                          Edit Condition
                        </UncontrolledTooltip>
                        <a
                          className="table-action table-action-delete"
                          href="#"
                          id="tooltip601065234"
                          onClick={() => removeCondition(condition.id)}
                        >
                          <i className="fas fa-trash" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip601065234">
                          Delete Condition
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Condition
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Medical Condition (Required)
                    </label>
                    <Input
                      id="input-condition"
                      placeholder=""
                      type="text"
                      value={selectedData.name}
                      onChange={e => setSelectedData({ ...selectedData, 'name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href="#"
            onClick={() => {
              modifyCondition(selectedData);

              // Close modal
              setdefaultModal(false);
            }}
            size="xl"
          >
            Update Condition
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedConditions = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(Conditions);

export default ConnectedConditions;
