import { useQuery } from '@tanstack/react-query'
import { connect } from 'react-redux'
import {
  Col,
  Container,
  Row
} from "reactstrap"
import { getHealthcareVisits } from '../../../supabase-api/healthcare_visits'
import { HcvForm } from '../shared/HcvForm'
import { HcvListTable } from '../shared/HcvListTable'
import { RxMgmtModalTutorialComprehensive } from './HcvModalTutorialComprehensive'
import { HeaderWithTutorial } from '../../shared/HeaderWithTutorial'
import { SubscriptionUpgradeRequired } from '../../subscriptions/SubscriptionUpgradeRequired'
import { isSubscriptionTierAvailable } from '../../../utils/subscriptionTierAvailablityCheck'
import { getActiveSubscriptions } from '../../../supabase-api/active_subscriptions'
import { getCurrentUser } from '../../../supabase-api/current_user'

function HealthcareVisitsComprehensive({ profile, medical, dispatch }: any) {
  const { data: healthcareVisits } = useQuery({
    queryKey: ['healthcare_visits'],
    queryFn: () => getHealthcareVisits(profile.user.id),
    enabled: !!profile.user.id
  })

  const { data: currentUser, isLoading: isCurrentUserLoading } = useQuery({
    queryKey: ['current_user'],
    queryFn: () => getCurrentUser(),
  })

  const { data: activeSubscriptions, isLoading: isLoadingActiveSubscriptions } = useQuery({
    queryKey: ['user_subscriptions'],
    queryFn: () => getActiveSubscriptions(currentUser!.id),
    retry: false,
    enabled: !!currentUser
  })

  if (isLoadingActiveSubscriptions || !activeSubscriptions || isCurrentUserLoading || isLoadingActiveSubscriptions) {
    return 'Loading...'
  }
  const isTierAvailable = isSubscriptionTierAvailable(activeSubscriptions.map(sub => sub.products!.name), 'Healthcare Visits Comprehensive')

  if (!isTierAvailable) {
    return <SubscriptionUpgradeRequired
      serviceName="Healthcare Visits"
      subscriptionTier="Comprehensive"
      subscriptionUrl={process.env.REACT_APP_SUBSCRIPTION_SITE_URL || '#'}
    />
  }

  return (
    <>
      <HeaderWithTutorial
        serviceName="Healthcare Visits"
        subscriptionTier="Comprehensive"
        tutorialContent={<RxMgmtModalTutorialComprehensive />}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <HcvForm
              subscriptionTier="comprehensive"
              formAction="create"
            />
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <HcvListTable
              subscriptionTier="comprehensive"
              items={healthcareVisits ?? []}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

const ConnectedHealthcareVisitsComprehensive = connect((state: any) => ({
  profile: state.profile,
  medical: state.medical
}))(HealthcareVisitsComprehensive)

export default ConnectedHealthcareVisitsComprehensive
