import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { RemindersUnsubscribePage } from '../views/medication-management/shared/RemindersUnsubscribePage'

export default function RemindersLayout() {
  return (
    <div className="reminders-layout">
      <Switch>
        <Route
          path="/reminders/unsubscribe"
          component={RemindersUnsubscribePage}
        />
        <Redirect from="/reminders" to="/reminders/unsubscribe" />
      </Switch>
    </div>
  )
}
