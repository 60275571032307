import { supabase } from '../lib/supabase'

export async function createCheckoutSession(price_id: string, coupon_id?: string) {
  const { data, error } = await supabase.functions.invoke('stripe-checkout', {
    body: { price_id, coupon_id },
  })
  if (error) {
    console.error("Error creating checkout session: ", error)
    throw error
  }
  return data
}

export async function createCustomerPortalSession() {
  console.log('createCustomerPortalSession')
  const { data, error } = await supabase.functions.invoke('create-stripe-portal-session', {
    body: {},
  })
  if (error) {
    console.error("Error creating customer portal session: ", error)
    throw error
  }
  return data
}