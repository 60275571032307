import PropTypes from "prop-types"
import React, { useRef, useState } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Button,
  Modal,
} from "reactstrap"
import { ModalHeader } from './ModalHeader'

type Props = {
  serviceName: string
  subscriptionTier: string
  tutorialContent?: React.ReactNode
}
export function HeaderWithTutorial({ serviceName, subscriptionTier, tutorialContent }: Props) {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
  const printableContentRef = useRef<HTMLDivElement>(null)

  const printTutorial = () => {
    if (printableContentRef.current) {
      const printWindow = window.open('', '_blank')
      printWindow!.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                color: #333;
                max-width: 64ch;
                margin: 0 auto;
                padding: 20px;
              }
              h2, h3 {
                color: #2c3e50;
              }
              img {
                max-width: 100%;
                height: auto;
              }
            </style>
          </head>
          <body>
            ${printableContentRef.current.innerHTML}
          </body>
        </html>
      `)
      printWindow!.document.close()
      printWindow!.print()
      printWindow!.onafterprint = () => console.log('done printing')
    }
  }

  return (
    <>
      <div className="header bg-info pb-6">
        <div className="mx-auto">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center px-0 py-2 py-md-4">
                <Col lg="12" xs="12" className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h2 className="h2 text-white d-inline-block mb-0">{serviceName}</h2>
                    <Breadcrumb
                      className="d-none d-md-inline-block ml-md-4"
                      listClassName="breadcrumb-links breadcrumb-dark"
                    >
                      <BreadcrumbItem>
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          <i className="fas fa-home" />
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          {subscriptionTier}
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem aria-current="page" className="active">
                        {serviceName}
                      </BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                  {tutorialContent &&
                    <>
                      {/* desktop help button */}
                      <Button
                        color="secondary"
                        className="d-none d-md-flex align-items-center px-4 py-2.5 mr-0"
                        onClick={() => setIsHelpModalOpen(true)}
                      >
                        <i className="fa-solid fa-circle-question mr-2" style={{ fontSize: "1.4rem" }} />
                        <span>{subscriptionTier} Guide</span>
                      </Button>

                      {/* mobile help button */}
                      <button
                        className="d-md-none bg-transparent border-0 p-0 mt-1"
                        onClick={() => setIsHelpModalOpen(true)}
                      >
                        <i className="fa-solid fa-circle-question text-white" style={{ fontSize: "1.75rem" }} />
                      </button>
                    </>
                  }
                </Col>
              </Row>
              <Row className="d-md-none py-2">
                <Col>
                  <h3 className="text-white" style={{ fontSize: 18 }}>{subscriptionTier}</h3>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        className="modal-dialog-centered"
        isOpen={isHelpModalOpen}
        toggle={() => setIsHelpModalOpen(false)}
        size="lg"
        style={{ maxWidth: '64ch' }}
      >
        <div ref={printableContentRef}>
          <ModalHeader
            onClose={() => setIsHelpModalOpen(false)}
          >
            <h2>{serviceName}</h2>
            <h3>{subscriptionTier}</h3>
            <Button
              className="px-4"
              onClick={printTutorial}
            >
              <i className="fa-solid fa-print mr-2" />
              Print
            </Button>
          </ModalHeader>
          {tutorialContent}
        </div>
      </Modal >
    </>
  )
}
