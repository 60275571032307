import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import { ComingSoon } from "../shared/ComingSoon"

export default function EnhancedCommsComingSoon() {
    return (
        <ComingSoon
            title="Enhanced Communications"
            description="A combination of SMS, voice enablement and email means that you can deliver critical health information easily to your Care Circle. Also, you can be alerted when someone accesses your QR code, providing an extra layer of security. With Enhanced Communications you have easier ways to quickly share your health and wellness needs."
        />
    )
}