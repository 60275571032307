import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import { ComingSoon } from "../shared/ComingSoon"

export default function RecordsMgmtComingSoon() {
    return (
        <ComingSoon
            title="Records Management"
            description="Health care is a sea of documents. Many are behind secure sites and you don’t have an easy way to download and save them. Records Management enables you to input all your records into a secure site, annotate them, essentially make them your own. You can even request the latest medical information based on your records. No matter where you are or what medical situation you are facing, you can have personalized information resulting in better more complete care with Records Management."
        />
    )
}