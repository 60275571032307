import { supabase } from '../lib/supabase'

const SUBSCRIPTIONS_TABLE = 'subscriptions'

export async function getActiveSubscriptions(profileId: string) {
    const currentTimestamp = Math.floor(Date.now() / 1000) // Current time in Unix timestamp (seconds)

    const { data } = await supabase
        .from(SUBSCRIPTIONS_TABLE)
        .select('*, products(stripe_product_id, name)')
        .eq('profile_id', profileId)
        .eq('status', 'active')
        .or(`cancel_at.gt.${currentTimestamp},cancel_at.is.null`)
        .throwOnError()
    return data
}
