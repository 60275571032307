import { Button } from 'reactstrap'
import { supabase } from '../../../lib/supabase'
import { useState } from 'react'

export function RemindersUnsubscribePage() {
  const [message, setMessage] = useState('')
  const reminderId = window.location.pathname.split('/').pop()

  const unsubscribe = async () => {
    const { data, error } = await supabase.functions.invoke('medication_reminder_unsubscribe', {
      body: { reminderId }
    })
    if (error) {
      console.error(error)
      setMessage('An error occurred. Please contact support@1yourhealth.com.')
    } else {
      setMessage('You have been unsubscribed from medication reminder emails.')
    }
  }
  return <div style={{ margin: '0 auto', width: '100%', textAlign: 'center', color: '#fff', padding: '100px 15px 15px 15px' }}>
    <div style={{ color: 'white', marginBottom: 30 }}>
      <p style={{ fontSize: 20 }}>
        Click below to turn off YourHealth medication reminder emails.
      </p>
      <p>You can always re-enable them on the Medication Management page.</p>
    </div>
    <Button color="primary" onClick={unsubscribe}>Unsubscribe</Button>
    {message && <p style={{ color: 'white', marginTop: 30 }}>{message}</p>}
  </div>
}
