import * as React from 'react'
import { Card, CardHeader, Col, Row, Table, UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import { HcvSubscriptionTier } from './types'
import { getHcvDetailsBasePath } from './getHcvDetailsBasePath'
import { supabase } from '../../../lib/supabase'
import { useQueryClient } from '@tanstack/react-query'
import { notifyError, notifySuccess } from '../../../utils/notify'
import { HealthCareVisitFull } from '../../../supabase-api/healthcare_visits'

type Props = {
  items: HealthCareVisitFull[]
  subscriptionTier: HcvSubscriptionTier
}
export function HcvListTable({ items, subscriptionTier }: Props) {
  const queryClient = useQueryClient()
  const hcvDetailsBasePath = getHcvDetailsBasePath(subscriptionTier)

  async function deleteHcv(id: string) {
    const { error } = await supabase
      .from('healthcare_visits')
      .delete()
      .eq('id', id)

    if (error) {
      console.error("Error deleting record: ", error)
      notifyError("Error deleting record.")
      return
    }

    queryClient.invalidateQueries({ queryKey: ['healthcare_visits'] })
    notifySuccess("Record deleted successfully.")
  }

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col xs="6">
            <h3 className="mb-0">Manage My Healthcare Visits</h3>
          </Col>
        </Row>
      </CardHeader>

      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Doctor</th>
            <th>Conditions</th>
            <th>Prescriptions</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {items.map((hcv) => (
            <tr key={hcv.id}>
              <td>
                <Link
                  className="font-weight-bold"
                  to={`${hcvDetailsBasePath}/${hcv.id}`}
                >
                  {hcv.name}
                </Link>
              </td>
              <td>
                <span className="text-muted">
                  {hcv.date}
                </span>
              </td>
              <td>
                <span className="text-muted">
                  {`${hcv.healthcare_visit_doctors[0]?.first_name} ${hcv.healthcare_visit_doctors[0]?.last_name}`}
                </span>
              </td>
              <td>
                <span className="text-muted">
                  {hcv.healthcare_visit_conditions.length ? hcv.healthcare_visit_conditions.map(c => c.name).join(', ') : 'None'}
                </span>
              </td>
              <td>
                <span className="text-muted">
                  {hcv.healthcare_visit_medications.length ? hcv.healthcare_visit_medications.map(m => m.name).join(', ') : 'None'}
                </span>
              </td>
              <td>
                <div>
                  <a
                    className="table-action table-action-delete ml-3"
                    href=""
                    id={`tooltip-delete-${hcv.id}`}
                    onClick={(e) => {
                      e.preventDefault()
                      deleteHcv(hcv.id)
                    }}
                  >
                    <i className="fas fa-trash" />
                  </a>
                  <UncontrolledTooltip delay={0} target={`tooltip-delete-${hcv.id}`}>
                    Delete Record
                  </UncontrolledTooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  )
}