import * as React from 'react'
import { Col, Row } from 'reactstrap'

type HcvMainTitleProps = {
  formAction: 'create' | 'update'
}
export function HcvMainTitle({ formAction }: HcvMainTitleProps) {
  return (
    <Row className="align-items-center">
      <Col xs="8">
        <h3 className="mb-0">
          {formAction === 'create' ? 'Add New Healthcare Visit' : 'Update Healthcare Visit'}
        </h3>
      </Col>
    </Row>
  )
}