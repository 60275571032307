import { HealthCareVisitFull } from "../supabase-api/healthcare_visits"

export function formatHcvImprovedHealthPrompt(hcv: HealthCareVisitFull) {
    const conditionsSubPrompt = hcv.healthcare_visit_conditions.length ? 'Associated Conditions: ' + hcv.healthcare_visit_conditions.map(c => c.name).join(', ') : ''
    const medicationsSubPrompt = hcv.healthcare_visit_medications.length ? 'Current Prescriptions: ' + hcv.healthcare_visit_medications.map(m => m.name).join(', ') : ''
    const patientNotesSubPrompt = hcv.notes ? `Patient's Notes: ${hcv.notes}` : ''
    const doctorNotesSubPrompt = hcv.doctor_notes ? `Doctor's Notes: ${hcv.doctor_notes}` : ''
    const doctorRecommendationsSubPrompt = hcv.doctor_recommendations ? `Doctor's Recommendations: ${hcv.doctor_recommendations}` : ''
    let prompt = `Given the details of this doctor's visit, what are some things this patient can be doing to improve their health? 
    ${conditionsSubPrompt}
    ${medicationsSubPrompt}
    ${patientNotesSubPrompt}
    ${doctorNotesSubPrompt}
    ${doctorRecommendationsSubPrompt}
    `.trim()

    prompt += "Please keep your response brief, and formatted an HTML. Don't include a header title in your response, just the content. Respond as if you're speaking directly to the patient, but keep your response short and to the point. Don't offer encouragement."
    return prompt
}