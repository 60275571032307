import { useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Modal, Row } from 'reactstrap'
import ReactDatetime from "react-datetime"
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createContact, getDoctors } from '../../../supabase-api/contacts'
import { getCurrentUser } from '../../../supabase-api/current_user'
import { createCondition, getConditions } from '../../../supabase-api/conditions'
import { createMedication, getPrescriptions } from '../../../supabase-api/medications'
import { notifyError, notifySuccess } from '../../../utils/notify'
import { AddDoctorModalForm } from '../../../components/ModalForms/AddDoctorModalForm'
import { DropdownSelect, Option } from '../../../components/Dropdowns/DropdownSelect'
import { AddConditionModalForm } from '../../../components/ModalForms/AddConditionModalForm'
import { AddPrescriptionModalForm } from '../../../components/ModalForms/AddPrescriptionModalForm'
import { CreateHcvBody, HcvSubscriptionTier, UpdateHcvBody } from './types'
import { Database } from '../../../types'
import { FormError } from '../../../components/FormComponents/FormError'
import { supabase } from '../../../lib/supabase'
import { HcvMainTitle } from './HcvMainTitle'
import { useHistory } from 'react-router'
import { getHcvDetailsBasePath } from './getHcvDetailsBasePath'
import { HealthcareVisitCondition, HealthcareVisitDoctor, HealthCareVisitFull, HealthcareVisitMedication } from '../../../supabase-api/healthcare_visits'


type HcvFormProps = {
  formAction?: 'create' | 'update'
  defaultValues?: HealthCareVisitFull
  subscriptionTier: HcvSubscriptionTier
  onCancelEdit?: () => void
}

type PrimaryFormFields = Pick<HealthCareVisitFull, 'name' | 'date' | 'notes' | 'doctor_notes' | 'doctor_recommendations'>

type Doctor = Database["public"]["Tables"]["contact"]["Row"]
type Condition = Database["public"]["Tables"]["condition"]["Row"]
type Prescription = Database["public"]["Tables"]["medication"]["Row"]

export function HcvForm({ formAction = 'create', subscriptionTier, defaultValues, onCancelEdit }: HcvFormProps) {
  const prepopulatedDoctor = defaultValues?.healthcare_visit_doctors[0]
  const prepopulatedConditions = defaultValues?.healthcare_visit_conditions
  const prepopulatedPrescriptions = defaultValues?.healthcare_visit_medications

  const [date, setDate] = useState<string>(defaultValues?.date ?? '')

  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | HealthcareVisitDoctor | undefined>(prepopulatedDoctor)
  const [selectedConditions, setSelectedConditions] = useState<(Condition | HealthcareVisitCondition)[]>(prepopulatedConditions ?? [])
  const [selectedPrescriptions, setSelectedPrescriptions] = useState<(Prescription | HealthcareVisitMedication)[]>(prepopulatedPrescriptions ?? [])

  const [showAddDoctorModal, setShowAddDoctorModal] = useState(false)
  const [showAddConditionModal, setShowAddConditionModal] = useState(false)
  const [showAddPrescriptionModal, setShowAddPrescriptionModal] = useState(false)

  const [showDateError, setShowDateError] = useState(false)
  const [showDoctorError, setShowDoctorError] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const history = useHistory()

  const queryClient = useQueryClient()

  const { data: currentUser } = useQuery({
    queryKey: ['current_user'],
    queryFn: () => getCurrentUser(),
  })

  // Gets the current list of doctors associated with the user.
  // If the user removes a doctor from their , then comes back to 
  // edit a previous healthcare visit associated with that doctor,
  // that doctor won't be in the list of current doctors, but
  // it still stays associated with that particular visit,
  // and needs to remain selectable.
  const { data: currentDoctors } = useQuery<Doctor[] | HealthcareVisitDoctor[]>({
    queryKey: ['doctors'],
    queryFn: () => getDoctors(currentUser!.id),
    enabled: !!currentUser?.id
  })

  // Gets the current list of conditions associated with the user.
  // Same logic as above with the current doctors.
  const { data: currentConditions } = useQuery({
    queryKey: ['conditions'],
    queryFn: () => getConditions(currentUser!.id),
    enabled: !!currentUser?.id
  })

  // Gets the current list of prescriptions associated with the user.
  // Same logic as above with the current doctors.
  const { data: currentPrescriptions } = useQuery({
    queryKey: ['prescriptions'],
    queryFn: () => getPrescriptions(currentUser!.id),
    enabled: !!currentUser?.id
  })

  const addDoctorMutation = useMutation({
    mutationFn: (newDoctor: Database["public"]["Tables"]["contact"]["Insert"]) => {
      return createContact(newDoctor)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['doctors'] })
      queryClient.invalidateQueries({ queryKey: ['contacts'] })
      setShowAddDoctorModal(false)
      notifySuccess("Doctor added successfully")
    },
    onError: (error) => {
      console.error("Error adding doctor:", error)
      notifyError("Error adding doctor")
    }
  })

  const addConditionMutation = useMutation({
    mutationFn: (newCondition: Database["public"]["Tables"]["condition"]["Insert"]) => {
      return createCondition(newCondition)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['conditions'] })
      setShowAddConditionModal(false)
      notifySuccess("Condition added successfully")
    },
    onError: (error) => {
      console.error("Error adding condition:", error)
      notifyError("Error adding condition")
    }
  })

  const addPrescriptionMutation = useMutation({
    mutationFn: (newMedication: Database["public"]["Tables"]["medication"]["Insert"]) => {
      return createMedication(newMedication)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['medications'] })
      queryClient.invalidateQueries({ queryKey: ['prescriptions'] })

      setShowAddPrescriptionModal(false)
      notifySuccess("Prescription added successfully")
    },
    onError: (error) => {
      console.error("Error adding prescription:", error)
      notifyError("Error adding prescription")
    }
  })

  const { register, handleSubmit, formState: { errors } } = useForm<PrimaryFormFields>({
    shouldUseNativeValidation: false,
    criteriaMode: "all",
    defaultValues: {
      name: defaultValues?.name,
      notes: defaultValues?.notes,
      doctor_notes: defaultValues?.doctor_notes,
      doctor_recommendations: defaultValues?.doctor_recommendations,
    }
  })

  const { ref: nameInputRef, ...registerName } = register('name', { required: "Please enter a name." })
  const { ref: notesInputRef, ...registerNotes } = register('notes')
  const { ref: doctorNotesInputRef, ...registerDoctorNotes } = register('doctor_notes')
  const { ref: doctorRecommendationsInputRef, ...registerDoctorRecommendations } = register('doctor_recommendations')

  const createHcv = async (requestBody: CreateHcvBody) => {
    setIsSubmitting(true)

    // @ts-ignore (TODO!!)
    const { data, error } = await supabase.rpc('create_healthcare_visit', {
      payload: requestBody
    })

    if (error || !data) {
      console.error("Error creating healthcare visit: ", error)
      notifyError("Error creating healthcare visit.")
      setIsSubmitting(false)
      return
    }

    const hcvDetailsBasePath = getHcvDetailsBasePath(subscriptionTier)

    notifySuccess("Healthcare visit added successfully.")
    setIsSubmitting(false)

    queryClient.invalidateQueries({ queryKey: ['healthcare_visits'] })
    if (formAction === 'create') {
      history.push(`${hcvDetailsBasePath}/${(data as any).healthcare_visit.id}`)
    }
  }

  const updateHcv = async (requestBody: UpdateHcvBody) => {
    setIsSubmitting(true)
    
    // @ts-ignore (TODO!!)
    const { data, error } = await supabase.rpc('update_healthcare_visit', {
      payload: requestBody
    })

    if (error || !data) {
      console.error("Error updating healthcare visit: ", error)
      notifyError("Error updating healthcare visit.")
      setIsSubmitting(false)
      return
    }

    notifySuccess("Healthcare visit updated successfully.")
    setIsSubmitting(false)
    queryClient.invalidateQueries({ queryKey: ['healthcare_visits'] })
    onCancelEdit?.()
  }

  const onSubmit = (primaryFormValues: PrimaryFormFields) => {
    // todo: display all validation errors at once
    if (!date) {
      setShowDateError(true)
      return
    } else {
      setShowDateError(false)
    }

    if (!selectedDoctor) {
      setShowDoctorError(true)
      return
    } else {
      setShowDoctorError(false)
    }

    if (formAction === 'create') {
      const requestBody: CreateHcvBody = {
        healthcare_visit: {
          name: primaryFormValues.name,
          date: date,
          notes: primaryFormValues.notes,
          profile_id: currentUser!.id,
          doctor_notes: primaryFormValues.doctor_notes,
          doctor_recommendations: primaryFormValues.doctor_recommendations,
        },
        healthcare_visit_doctor: {
          first_name: selectedDoctor.first_name as string, // todo
          last_name: selectedDoctor.last_name as string,
          email: selectedDoctor.email,
          phone: selectedDoctor.phone,
          original_contact_id: selectedDoctor.id,
          contact_type_id: selectedDoctor.contact_type_id,
          profile_id: currentUser!.id,
        },
        healthcare_visit_conditions: selectedConditions?.map(condition => ({
          name: condition.name,
          profile_id: currentUser!.id,
          original_condition_id: condition.id,
        })) ?? [],
        healthcare_visit_medications: selectedPrescriptions?.map(prescription => ({
          name: prescription.name,
          dose: prescription.dose,
          frequency: prescription.frequency,
          medication_type_id: prescription.medication_type_id,
          profile_id: currentUser!.id,
          original_medication_id: prescription.id,
        })) ?? [],
      }
      createHcv(requestBody)

    } else {
      const requestBody: UpdateHcvBody = {
        healthcare_visit: {
          id: defaultValues?.id,
          name: primaryFormValues.name,
          date: date,
          notes: primaryFormValues.notes,
          profile_id: currentUser!.id,
          doctor_notes: primaryFormValues.doctor_notes,
          doctor_recommendations: primaryFormValues.doctor_recommendations,
        },
        healthcare_visit_doctor: {
          id: selectedDoctor.id,
          healthcare_visit_id: defaultValues?.id,
          first_name: selectedDoctor.first_name as string,
          last_name: selectedDoctor.last_name as string,
          email: selectedDoctor.email,
          phone: selectedDoctor.phone,
          original_contact_id: selectedDoctor.id,
          contact_type_id: selectedDoctor.contact_type_id,
          profile_id: currentUser!.id,
        },
        healthcare_visit_conditions: selectedConditions?.map(condition => ({
          id: condition.id,
          healthcare_visit_id: defaultValues?.id,
          name: condition.name,
          profile_id: currentUser!.id,
          original_condition_id: condition.id,
        })) ?? [],
        healthcare_visit_medications: selectedPrescriptions?.map(prescription => ({
          id: prescription.id,
          healthcare_visit_id: defaultValues?.id,
          name: prescription.name,
          dose: prescription.dose,
          frequency: prescription.frequency,
          medication_type_id: prescription.medication_type_id,
          profile_id: currentUser!.id,
          original_medication_id: prescription.id,
        })) ?? [],
      }
      updateHcv(requestBody)
    }
  }

  if (!currentDoctors || !currentConditions || !currentPrescriptions) {
    return <div>Loading...</div>
  }

  const allDoctors = [...currentDoctors.filter(doctor => doctor.id !== prepopulatedDoctor?.original_contact_id), prepopulatedDoctor]
  const allConditions = [...currentConditions.filter(condition => !prepopulatedConditions?.find(pc => pc.original_condition_id === condition.id)), prepopulatedConditions].flat()
  const allPrescriptions = [...currentPrescriptions.filter(prescription => !prepopulatedPrescriptions?.find(pp => pp.original_medication_id === prescription.id)), prepopulatedPrescriptions].flat()

  return (
    <Card>
      <CardHeader>
        <HcvMainTitle formAction={formAction} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <div className="d-flex justify-content-between align-items-start">
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Name (Required)
                    </label>
                    {errors.name && <FormError>{errors.name.message}</FormError>}
                  </div>
                  <Input
                    id="input-name"
                    placeholder="Name"
                    type="text"
                    innerRef={nameInputRef}
                    autoComplete="off"
                    {...registerName}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <div className="d-flex justify-content-between align-items-start">
                    <label className="form-control-label">
                      Date (Required)
                    </label>
                    {showDateError && <FormError>Please enter a date.</FormError>}
                  </div>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "01/01/2024",
                    }}
                    initialValue={date}
                    timeFormat={false}
                    onChange={(date) => date ? setDate((date as moment.Moment).format('MM/DD/YYYY')) : setDate('')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" xs="12">
                <div className="mb-3">
                  <div className="d-flex justify-content-between align-items-start">
                    <label className="form-control-label">Doctor (Required)</label>
                    {showDoctorError && <FormError>Please select a doctor.</FormError>}
                  </div>
                  <div className="d-flex flex-column flex-lg-row align-items-center">
                    <div className="w-100 flex-grow-1 mb-3 mb-lg-0">
                      {currentDoctors &&
                        <DropdownSelect
                          options={allDoctors.map(doctor => {
                            if (!doctor) {
                              console.warn('No doctors found.')
                              return { value: '', label: '' }
                            }
                            return { value: doctor.id, label: `${doctor.first_name} ${doctor.last_name}` }
                          })}
                          defaultSelected={formAction === 'update' && selectedDoctor ? [{ value: selectedDoctor.id, label: `${selectedDoctor.first_name} ${selectedDoctor.last_name}` }] : undefined}
                          onChange={(doctor) => {
                            const newSelectedDoctor = allDoctors.find(d => d?.id === (doctor as Option).value)
                            if (!newSelectedDoctor) return
                            setSelectedDoctor(newSelectedDoctor)
                          }}
                        />
                      }
                    </div>
                    <Button
                      style={{ minWidth: 180 }}
                      className="ml-lg-3 py-2 align-self-end"
                      onClick={() => setShowAddDoctorModal(true)}
                    >
                      Add Doctor
                    </Button>
                    <Modal
                      className="modal-dialog-centered"
                      isOpen={showAddDoctorModal}
                      toggle={() => setShowAddDoctorModal(false)}
                    >
                      <AddDoctorModalForm
                        onSubmit={(newDoctor) => { addDoctorMutation.mutate(newDoctor) }}
                        onCancel={() => setShowAddDoctorModal(false)}
                      />
                    </Modal>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-control-label" >Associated Conditions</label>
                  <div className="d-flex flex-column flex-lg-row align-items-center">
                    <div className="w-100 flex-grow-1 mb-3 mb-lg-0">
                      {currentConditions &&
                        <DropdownSelect
                          options={allConditions.map(condition => {
                            if (!condition) {
                              console.warn('No conditions found.')
                              return { value: '', label: '' }
                            }
                            return { value: condition.id, label: condition.name }
                          })}
                          multiple
                          defaultSelected={selectedConditions?.map(condition => {
                            return { value: condition.id, label: condition.name }
                          })}
                          onChange={(condition) => {
                            const newSelectedConditions = allConditions.filter(c => (condition as Option[]).map(o => o.value).includes(c?.id ?? ''))
                            setSelectedConditions(newSelectedConditions as (Condition | HealthcareVisitCondition)[])
                          }}
                        />
                      }
                    </div>
                    <Button
                      style={{ minWidth: 180 }}
                      className="ml-lg-3 py-2 align-self-end"
                      onClick={() => setShowAddConditionModal(true)}
                    >
                      Add Condition
                    </Button>
                    <Modal
                      className="modal-dialog-centered"
                      isOpen={showAddConditionModal}
                      toggle={() => setShowAddConditionModal(false)}
                    >
                      <AddConditionModalForm
                        onSubmit={(newCondition) => { addConditionMutation.mutate(newCondition) }}
                        onCancel={() => setShowAddConditionModal(false)}
                      />
                    </Modal>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-control-label" >Current Prescriptions</label>
                  <div className="d-flex flex-column flex-lg-row align-items-center">
                    <div className="w-100 flex-grow-1 mb-3 mb-lg-0">
                      {currentPrescriptions &&
                        <DropdownSelect
                          options={allPrescriptions?.map(prescription => {
                            if (!prescription) {
                              console.warn('No prescriptions found.')
                              return { value: '', label: '' }
                            }
                            return { value: prescription.id, label: prescription.name }
                          })}
                          defaultSelected={formAction === "update" ?
                            selectedPrescriptions?.map(prescription => {
                              return { value: prescription.id, label: prescription.name }
                            })
                            :
                            currentPrescriptions?.map(prescription => {
                              return { value: prescription.id, label: prescription.name }
                            })
                          }

                          multiple
                          onChange={(prescription) => {
                            const newSelectedPrescriptions = allPrescriptions.filter(p => (prescription as Option[]).map(o => o.value).includes(p?.id ?? ''))
                            setSelectedPrescriptions(newSelectedPrescriptions as (Prescription | HealthcareVisitMedication)[])
                          }}
                        />
                      }
                    </div>
                    <Button
                      style={{ minWidth: 180 }}
                      className="ml-lg-3 py-2 align-self-end"
                      onClick={() => setShowAddPrescriptionModal(true)}
                    >
                      Add Prescription
                    </Button>
                    <Modal
                      className="modal-dialog-centered"
                      isOpen={showAddPrescriptionModal}
                      toggle={() => setShowAddPrescriptionModal(false)}
                    >
                      <AddPrescriptionModalForm
                        onSubmit={(newPrescription) => { addPrescriptionMutation.mutate(newPrescription) }}
                        onCancel={() => setShowAddPrescriptionModal(false)}
                      />
                    </Modal>
                  </div>
                </div>
              </Col>
              <Col lg="6" xs="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-notes"
                  >
                    My Notes
                  </label>
                  <Input
                    id="input-notes"
                    rows="5"
                    style={{ height: 210 }}
                    placeholder="My notes"
                    type="textarea"
                    innerRef={notesInputRef}
                    {...registerNotes}
                  />
                </FormGroup>
              </Col>
            </Row>
            {subscriptionTier === 'comprehensive' &&
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-dr-notes"
                    >
                      Doctor's Notes
                    </label>
                    <Input
                      id="input-dr-notes"
                      rows="5"
                      style={{ height: 180 }}
                      placeholder="Doctor's notes"
                      type="textarea"
                      innerRef={doctorNotesInputRef}
                      {...registerDoctorNotes}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-dr-recommendations"
                    >
                      Doctor's Recommendations
                    </label>
                    <Input
                      id="input-dr-recommendations"
                      rows="5"
                      style={{ height: 180 }}
                      placeholder="Doctor's recommendations"
                      type="textarea"
                      innerRef={doctorRecommendationsInputRef}
                      {...registerDoctorRecommendations}
                    />
                  </FormGroup>
                </Col>
              </Row>
            }
          </div>
          <hr className="my-4" />
          <Row>
            <Col className="text-right">
              {formAction === 'update' &&
                <Button onClick={onCancelEdit}>Cancel</Button>
              }
              <Button
                color="primary"
                type="submit"
                size="xl"
                disabled={isSubmitting}
              >
                {formAction === 'update' ? 'Update Healthcare Visit' : 'Add Healthcare Visit'}
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}