export function RxMgmtModalTutorialAdvanced() {
  return (
    <div className="modal-body pt-0">
      {/* title is derived from the serviceName and subscriptionTier props passed to the HeaderWithTutorial component */}
      <p>
        Scroll over to the left column using your finger (mobile) or your mouse/pad (laptop).
        This will show you the YourHealth Life Advanced that you subscribed to; click or tap to
        the highlighted Advanced application. This screen will appear.
      </p>
      <p>
        Before you begin using <span className="font-weight-bold">Medication Management Advanced</span>, make sure that you have completed
        your Starter application; it is part of your Advanced subscription. As in the Starter
        application you can add new medications and update information on xisting
        medications. The Advanced tier gives you additional features with better information
        and understanding of the medications you are taking. When you click on a
        medication you can see new buttons at the bottom of the page to get more
        information.
      </p>
      <div className="mb-5" style={{ maxWidth: 600 }}>
        <img
          src="/tutorials/medication-management/advanced/details.jpg"
          alt="Medication Management Advanced Detailed View"
          className="img-fluid shadow rounded-sm"
        />
      </div>
      <p>
        By selecting these buttons, YourHealth Life will go and retrieve alternative
        medications and or latest research.
      </p>
      <p>
        Remember to consult with your doctor any time you get information from
        YourHealth.
      </p>
    </div>
  )
}