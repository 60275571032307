export function RxMgmtModalTutorialAdvanced() {
  return (
    <div className="modal-body pt-0">
      {/* title is derived from the serviceName and subscriptionTier props passed to the HeaderWithTutorial component */}
      <p>
        Be sure that you have gone through and added any information in your
        Starter Healthcare Visit before using the Advanced application.
        The Advanced Healthcare Visit tier looks very similar to the Starter tier with
        some important additions.
      </p>
      <p>
        First, you can always add additional notes that you include any time, before
        or after a healthcare visit. If you add anything, click the button that says
        Update Healthcare Visit to save any changes.
      </p>
      <p>
        Second, in this section of YourHealth Life Insights there is a button. Are there
        medical treatments or issues that could affect my current treatment? By
        selecting this, button, the application will gather the information that you
        have put in and search for anything additional that you might wish to be
        aware of.
      </p>
      <p>
        As always consult your health professional with any results that you obtain or
        questions that you might have.
      </p>
      <div style={{ maxWidth: 600 }}>
        <img
          src="/tutorials/healthcare-visits/advanced/hcv1.png"
          alt="Healthcare Visits Starter Form"
          className="img-fluid shadow rounded-sm"
        />
      </div>
    </div>
  )
}
