import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import CardFooter from "../components/Footers/CardFooter.js";
import routes from "../routes.card";
import { useSupabase } from "../lib/useSupabase";
import { createClient } from '@supabase/supabase-js'
import { connect } from 'react-redux';
import { INITIALIZE_CARD, INITIALIZE_PROFILE } from '../store/actions';
import {
  Card,
  Container,
  Row, Col,
  Button,
  CardBody,
  CardText,
  Alert
} from 'reactstrap'
import CareCardHeader from "../components/Headers/CareCardHeader.js";
import { notifyError } from '../utils/notify';

function CardLayout({ card, profile, medical, dispatch }) {
  const location = useLocation();
  const history = useHistory()
  const mainContentRef = useRef(null);
  const { supabaseInstance, setSupbaseInstance } = useSupabase()
  const [isCardActive, setIsCardActive] = useState(true)

  useEffect(() => {
    if (supabaseInstance) return
    document.body.classList.add("bg-white");
    const cardTypeUrlParam = location.pathname.split('/')[3]
    const shareKeyUrlParam = location.pathname.split('/')[4]
    const sb = createClient(
      process.env.REACT_APP_SUPABASE_URL ?? '',
      process.env.REACT_APP_SUPABASE_ANON_KEY ?? '',
      {
        global: {
          headers: {
            'Content-Type': 'application/json',
            'X-Share-Key': shareKeyUrlParam,
            'X-Card-Type': cardTypeUrlParam
          }
        },
        auth: {
          storageKey: 'sb-share-key-token'
        }
      }
    )

    sb.from('card')
      .select(`*, card_type!inner(*)`)
      .eq('share_key', shareKeyUrlParam)
      .eq('card_type.name', cardTypeUrlParam)
      // .eq('profile_id', profile.user.id)
      .single()
      .then(({ data: cardData, error: cardError }) => {
        if (cardError) {
          console.error("Error getting card: ", cardError)
          notifyError("Card not found.")
          // window.location.replace(`https://legacy.portal.yourhealth.app/card/view/${cardTypeUrlParam}/${shareKeyUrlParam}`)
          return
        }

        if (!cardData.is_active) {
          console.error('card is not active')
          setIsCardActive(false)
          return
        }

        if (!card.initialized) {
          dispatch({
            type: INITIALIZE_CARD,
            payload: cardTypeUrlParam
          })
        }

        sb.from('profile')
          .select("*")
          .eq('id', cardData.profile_id)
          .single()
          .then(({ data: profileData, profileError }) => {
            if (profileError) {
              console.error("Error getting profile: ", profileError)
              setIsCardActive(false)
              return
            }

            dispatch({
              type: INITIALIZE_PROFILE,
              payload: profileData
            })
          })

      })


    setSupbaseInstance(sb)
  }, [supabaseInstance, location])

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/card") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (!isCardActive) {
    return (
      <>
        <CareCardHeader
          display={true}
          profile={profile}
          card={card}
          description="Essential Care Card"
          supabaseInstance={supabaseInstance}
        />
        <Card className="mt--6 pb-2 mx-auto" style={{ maxWidth: 1200 }}>
          <CardBody style={{ maxWidth: 900 }} className="mx-auto">
            <CardText className="text-center pl-2 pr-2 my-3 my-lg-4 font-weight-bold">
              This QR code is no longer active, please contact the member.
            </CardText>
          </CardBody>
        </Card>
        <div className="bg-white flex-1 mt-6">
          <CardFooter />
        </div>
      </>
    )
  }

  return (
    <>
      <CareCardHeader
        display={true}
        profile={profile}
        card={card}
        description="Essential Care Card"
        supabaseInstance={supabaseInstance}
      />
      <div
        className="main-content mx-auto"
        ref={mainContentRef}
        style={{ maxWidth: 1200 }}
      >
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/card/medical/1" />
        </Switch>
      </div>
      <div className="bg-white flex-1">
        <CardFooter />
      </div>
    </>
  )
}

const ConnectedCardLayout = connect(state => ({
  card: state.card,
  profile: state.profile,
  medical: state.medical,
}))(CardLayout)

export default ConnectedCardLayout
