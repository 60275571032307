import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { ModalFormHeader } from './ModalFormHeader'
import { getPrescriptionMedicationType } from '../../supabase-api/medication_types'
import { Database } from '../../types'
import { notifyError } from '../../utils/notify'
import { stopPropagate } from './stopPropagate'

type AddPrescriptionModalFormProps = {
  onSubmit: (data: Database["public"]["Tables"]["medication"]["Insert"]) => void
  onCancel: () => void
}

export function AddPrescriptionModalForm({ onSubmit, onCancel }: AddPrescriptionModalFormProps) {

  const { data: prescriptionMedicationType } = useQuery({
    queryKey: ['prescription_medication_type'],
    queryFn: () => getPrescriptionMedicationType(),
  })

  const { register, handleSubmit } = useForm<Database["public"]["Tables"]["medication"]["Insert"]>()
  const { ref: nameInputRef, ...registerName } = register('name')
  const { ref: doseInputRef, ...registerDose } = register('dose')
  const { ref: frequencyInputRef, ...registerFrequency } = register('frequency')
  const { ref: dateStartedInputRef, ...registerDateStarted } = register('date_started')

  const _onSubmit = (data: Database["public"]["Tables"]["medication"]["Insert"]) => {
    if (!prescriptionMedicationType) {
      console.error('Unable to get prescription medication type')
      notifyError('Error adding prescription')
      return
    }
    onSubmit({ ...data, medication_type_id: prescriptionMedicationType.id })
  }

  return (
    <>
      <ModalFormHeader onClose={() => onCancel()}>
        Add New Prescription
      </ModalFormHeader>
      <Form onSubmit={stopPropagate(handleSubmit(_onSubmit))}>
        <div className="p-4">
          <Row>
            <Col xs="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-name"
                >
                  Name
                </label>
                <Input
                  innerRef={nameInputRef}
                  id="input-name"
                  placeholder="Prescription name"
                  type="text"
                  autoComplete="off"
                  {...registerName}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-dose"
                >
                  Dose
                </label>
                <Input
                  innerRef={doseInputRef}
                  id="input-dose"
                  placeholder="Prescription dose"
                  type="text"
                  autoComplete="off"
                  {...registerDose}
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-frequency"
                >
                  Frequency
                </label>
                <Input
                  innerRef={frequencyInputRef}
                  id="input-frequency"
                  placeholder="Prescription frequency"
                  type="text"
                  autoComplete="off"
                  {...registerFrequency}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <Button
            color="secondary"
            type="button"
            onClick={onCancel}
            size="xl"
            style={{ width: 120 }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            size="xl"
            style={{ width: 120 }}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  )
}