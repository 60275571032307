import { useQuery } from '@tanstack/react-query'
import { useHistory } from 'react-router'
import { createCustomerPortalSession } from '../../supabase-api/stripe'
import { getCurrentUser } from '../../supabase-api/current_user'

export default function CustomerPortal() {

    const history = useHistory()

    const { data: currentUser, isLoading: isCurrentUserLoading } = useQuery({
        queryKey: ['current_user'],
        queryFn: () => getCurrentUser(),
    })

    const { data: portalSession } = useQuery({
        queryKey: ['customer_portal'],
        queryFn: () => createCustomerPortalSession(),
        retry: false,
        enabled: !!currentUser
    })

    console.log("currentUser", currentUser)

    console.log("portalSession", portalSession)

    // if (isCurrentUserLoading) {
    //     return null
    // }

    if (!currentUser && !isCurrentUserLoading) {
        history.replace('/auth/login')
    }

    if (portalSession) {
        window.location.href = portalSession.url
    }

    return (
        <div style={{ width: '100vw', height: '100dvh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
                className="spinner-border mr-2"
                role="status"
                style={{
                    width: 20,
                    height: 20,
                    marginRight: 10
                }}
            />
            <span>Loading...</span>
        </div>
    )
}