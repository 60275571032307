import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { HealthCareVisitFull } from '../../../supabase-api/healthcare_visits'
import { HcvSubscriptionTier } from './types'

type HcvDetailsProps = {
  healthcareVisit: HealthCareVisitFull
  subscriptionTier: HcvSubscriptionTier
  onEdit?: () => void
}
export function HcvDetails({ healthcareVisit, subscriptionTier, onEdit }: HcvDetailsProps) {
  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="8">
            <div className="d-flex align-items-center">
              <Link to="/healthcare-visits/healthcare-starter">
                <Button
                  color="secondary"
                  style={{
                    fontSize: '.75rem',
                    marginRight: '1.5rem'
                  }}
                >
                  <div className="d-flex align-items-center">
                    <svg className="mr-2" style={{ width: 10, height: 10 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    Back
                  </div>
                </Button>
              </Link>
              <h1 className="mb-0">{healthcareVisit.name}</h1>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="text-sm px-4">
          <Col xs="12" lg="6">
            <div>
              <h4>Doctor</h4>
              <p>{`${healthcareVisit.healthcare_visit_doctors[0].first_name} ${healthcareVisit.healthcare_visit_doctors[0].last_name}`}</p>
            </div>
            <div>
              <h4>Associated Conditions</h4>
              <p>{healthcareVisit.healthcare_visit_conditions.length ? healthcareVisit.healthcare_visit_conditions.map(c => c.name).join(', ') : 'None'}</p>
            </div>
            <div>
              <h4>Associated Prescriptions</h4>
              <p>{healthcareVisit.healthcare_visit_medications.length ? healthcareVisit.healthcare_visit_medications.map(m => m.name).join(', ') : 'None'}</p>
            </div>
          </Col>
          <Col lg="6">
            <div>
              <h4>Date</h4>
              <p>{healthcareVisit.date}</p>
            </div>
            <div>
              <h4>My Notes</h4>
              <p>{healthcareVisit.notes && healthcareVisit.notes !== '' ? healthcareVisit.notes : 'None'}</p>
            </div>
          </Col>
        </Row>
        {subscriptionTier === 'comprehensive' &&
          <Row className="text-sm px-4">
            <Col lg="6">
              <h4>Doctor's Notes</h4>
              <p>{healthcareVisit.doctor_notes && healthcareVisit.doctor_notes !== '' ? healthcareVisit.doctor_notes : 'None'}</p>
            </Col>
            <Col lg="6">
              <h4>Doctor's Recommendations</h4>
              <p>{healthcareVisit.doctor_recommendations && healthcareVisit.doctor_recommendations !== '' ? healthcareVisit.doctor_recommendations : 'None'}</p>
            </Col>
          </Row>
        }
        <div className="d-flex justify-content-end w-100">
          <Button
            className="px-4"
            onClick={() => onEdit?.()}
          >
            Edit
          </Button>
        </div>
      </CardBody>
    </Card>
  )
}
