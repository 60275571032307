import PhoneInput from 'react-phone-number-input/input'
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { getDoctorContactTypes } from '../../supabase-api/contact_types'
import { ModalFormHeader } from './ModalFormHeader'
import { stopPropagate } from './stopPropagate'

type AddDoctorModalFormProps = {
  onSubmit: (data: AddDoctorFormFields, e: any) => void
  onCancel: () => void
}

type AddDoctorFormFields = {
  first_name?: string
  last_name: string
  phone?: string
  email?: string
  contact_type_id: string
}


export function AddDoctorModalForm({ onSubmit, onCancel }: AddDoctorModalFormProps) {

  const { data: doctorContactTypes } = useQuery({
    queryKey: ['doctor_contact_types'],
    queryFn: () => getDoctorContactTypes(),
  })

  const { register, handleSubmit } = useForm<AddDoctorFormFields>()
  const { ref: firstNameInputRef, ...registerFirstName } = register('first_name')
  const { ref: lastNameInputRef, ...registerLastName } = register('last_name')
  const { ref: emailInputRef, ...registerEmail } = register('email')
  const { ref: contactTypeIdRef, ...registerContactTypeId } = register('contact_type_id')

  return (
    <>
      <ModalFormHeader onClose={() => onCancel()}>
        Add New Doctor
      </ModalFormHeader>
      <Form onSubmit={stopPropagate(handleSubmit(onSubmit))}>
        <div className="p-4">
          <Row>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-first-name"
                >
                  First Name
                </label>
                <Input
                  innerRef={firstNameInputRef}
                  id="input-first-name"
                  placeholder="First name"
                  type="text"
                  autoComplete="off"
                  {...registerFirstName}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-last-name"
                >
                  Last Name
                </label>
                <Input
                  innerRef={lastNameInputRef}
                  id="input-last-name"
                  placeholder="Last name"
                  type="text"
                  autoComplete="off"
                  {...registerLastName}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="doctor-type"
                >
                  Doctor Type
                </label>
                {doctorContactTypes && <Input
                  innerRef={contactTypeIdRef}
                  id="doctor-type"
                  type="select"
                  defaultValue={doctorContactTypes?.[0]?.id}
                  {...registerContactTypeId}
                >
                  {doctorContactTypes?.map((contactType) => (
                    <option key={contactType.id} value={contactType.id}>
                      {contactType.name}
                    </option>
                  ))}
                </Input>}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-phone"
                >
                  Phone Number
                </label>
                <PhoneInput
                  {...register("phone")}
                  id="input-phone"
                  className="form-control"
                  placeholder="Phone number"
                  defaultCountry="US"
                  autoComplete="off"
                  onChange={(phone) => { }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-email"
                >
                  Email Address
                </label>
                <Input
                  innerRef={emailInputRef}
                  id="input-email"
                  placeholder="Email address"
                  type="email"
                  autoComplete="off"
                  {...registerEmail}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            type="button"
            onClick={onCancel}
            size="xl"
            style={{ width: 120 }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            size="xl"
            style={{ width: 120 }}
          >
            Save
          </Button>
        </div>
      </Form >
    </>
  )
}
