export function isSubscriptionTierAvailable(activeSubscriptionNames: string[], subscriptionToCheck: string) {
    if (process.env.REACT_APP_ENVIRONMENT !== 'prod') return true

    if (!activeSubscriptionNames) return false
    const tiers = ['Starter', 'Advanced', 'Comprehensive']
    const categories = ['Healthcare Visits', 'Medication Management', 'Records Management', 'Enhanced Communications']

    const [categoryToCheck, tierToCheck] = subscriptionToCheck.split(' ').reduce((acc, word, index, arr) => {
        if (index === arr.length - 1) {
            acc[1] = word
        } else {
            acc[0] += (index === 0 ? '' : ' ') + word
        }
        return acc
    }, ['', ''])

    const activeTierForCategory = activeSubscriptionNames
        .filter(sub => sub.startsWith(categoryToCheck))
        .map(sub => sub.split(' ').pop())
        .sort((a, b) => tiers.indexOf(b!) - tiers.indexOf(a!))
    [0]

    if (!activeTierForCategory) return false

    return tiers.indexOf(activeTierForCategory) >= tiers.indexOf(tierToCheck)
}
