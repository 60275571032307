import { useEffect } from 'react'
import { notifySuccess } from '../../utils/notify'

export default function BillingSuccess() {
    useEffect(() => {
        notifySuccess('Subscription successful')
        window.location.href = '/admin/account'
    }, [])
    return (
        <div style={{ width: '100vw', height: '100dvh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
                className="spinner-border mr-2"
                role="status"
                style={{
                    width: 20,
                    height: 20,
                    marginRight: 10
                }}
            />
            <span>Loading...</span>
        </div>
    )
}