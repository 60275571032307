import { PropsWithChildren } from 'react'

type ModalFormHeaderProps = {
  onClose: () => void
}
export function ModalHeader({ onClose, children }: PropsWithChildren<ModalFormHeaderProps>) {
  return (
    <div className="modal-header">
      <div className="modal-title">
        {children}
      </div>
      <button
        aria-label="Close"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={onClose}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
  )
}