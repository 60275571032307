import { useQuery } from '@tanstack/react-query'
import { Card, CardHeader, Row, Col, Button, CardBody, Container } from 'reactstrap'
import { getCurrentUser } from '../../../supabase-api/current_user'
import { getActiveSubscriptions } from '../../../supabase-api/active_subscriptions'

export function ManageBilling() {
  const { data: currentUser, isLoading: isCurrentUserLoading } = useQuery({
    queryKey: ['current_user'],
    queryFn: () => getCurrentUser(),
  })

  const { data: activeSubscriptions, isLoading: isLoadingActiveSubscriptions } = useQuery({
    queryKey: ['user_subscriptions'],
    queryFn: () => getActiveSubscriptions(currentUser!.id),
    retry: false,
    enabled: !!currentUser
  })

  if (isLoadingActiveSubscriptions || !activeSubscriptions) {
    return 'Loading...'
  }

  console.log("activeSubscriptions", activeSubscriptions)

  return (
    <Container fluid>
      <Card>
        <CardHeader>
          <h3>Billing</h3>
        </CardHeader>
        <CardBody>
          <Row className="text-sm px-4">
            <Col xs="12" lg="6">
              <div>
                <h3>Current Subscriptions</h3>
                <ul className="" style={{ fontSize: '1rem' }}>
                  <li>Essential</li>
                  {activeSubscriptions.map((subscription) => (
                    <li key={subscription.id}>{subscription.products?.name}</li>
                  ))}
                </ul>
                <p style={{ fontSize: '.9rem' }}></p>
              </div>
            </Col>
            {activeSubscriptions.length === 0 &&
              <Col xs="12" lg="6">
                <h3>Subscribe to YourHealth Life</h3>

                <Button color="primary" href={process.env.REACT_APP_SUBSCRIPTION_SITE_URL || '#'} target="_blank">View Subscription Options</Button>
              </Col>
            }
            {activeSubscriptions.length > 0 &&
              <Col xs="12" lg="6">
                <h3>Manage Billing</h3>

                <p style={{ fontSize: '.9rem' }}>To manage your current subscriptions, billing information and payment methods, click the button below to be taken to the Stripe Billing Portal.</p>
                <div>
                  <Button
                    color="primary"
                    href={'/billing/customer-portal'}
                  >
                    Go to Billing Portal
                  </Button>
                </div>
              </Col>}
          </Row>
        </CardBody>
      </Card>
    </Container>
  )
}
