import { Button, Card } from "reactstrap"

export function ComingSoon({ title, description }: { title: string, description?: string }) {
    return (
        <Card>

            <div className="w-full p-4 p-md-7">
                <h1 style={{ fontSize: 36, marginBottom: 20 }}>{title}</h1>
                <p style={{ marginBottom: 20, maxWidth: '64ch' }}>{description}</p>
                <p className="font-weight-bold">Coming soon.</p>
                <Button color="primary" href={process.env.REACT_APP_SUBSCRIPTION_SITE_URL} target="_blank" rel="noreferrer">Learn More</Button>
            </div>
        </Card>

    )
}
