import { useQuery, useQueryClient } from '@tanstack/react-query'
import { createReminder, deleteReminder, getReminders } from '../../../supabase-api/reminders'
import { getCurrentUser } from '../../../supabase-api/current_user'
import { notifySuccess } from '../../../utils/notify'

export function MedicationReminderCheckbox() {
  const queryClient = useQueryClient()
  const { data: currentUser, isLoading: isCurrentUserLoading } = useQuery({
    queryKey: ['current_user'],
    queryFn: () => getCurrentUser(),
  })

  const { data: reminders, isLoading: isLoadingReminders } = useQuery({
    queryKey: ['reminders'],
    queryFn: () => getReminders(currentUser!.id),
    retry: false,
    enabled: !!currentUser
  })

  function toggleReminders(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      createReminder(currentUser!.id)
      queryClient.invalidateQueries({ queryKey: ['reminders'] })
      notifySuccess('Reminders enabled')
    } else {
      deleteReminder(currentUser!.id)
      queryClient.invalidateQueries({ queryKey: ['reminders'] })
      notifySuccess('Reminders disabled')
    }
  }

  if (isCurrentUserLoading || isLoadingReminders) {
    return 'Loading...'
  }

  return (
    <label className="text-sm d-flex align-items-start align-items-lg-center justify-content-end">
      <input
        type="checkbox"
        id="opt-in-checkbox"
        style={{ width: '1.2rem', height: '1.5rem' }}
        defaultChecked={!!reminders}
        onChange={toggleReminders}
      />
      <span className="ml-2" style={{ fontSize: '.9rem' }}>Check this box to receive daily emailed medication reminders.</span>
    </label>
  )
}