import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import BillingSuccess from "../views/billing/BillingSuccess"
import BillingFailure from "../views/billing/BillingFailure"
import Checkout from "../views/billing/Checkout"
import CustomerPortal from "../views/billing/CustomerPortal"
import BillingCancel from "../views/billing/BillingCancel"
import { ToastContainer } from 'react-toastify'

export default function BillingLayout() {
  return (
    <div className="billing-layout">
      <Switch>
        <Route
          path="/billing/success"
          component={BillingSuccess}
        />
        <Route
          path="/billing/failure"
          component={BillingFailure}
        />
        <Route
          path="/billing/checkout"
          component={Checkout}
        />
        <Route
          path="/billing/customer-portal"
          component={CustomerPortal}
        />
        <Route
          path="/billing/cancel"
          component={BillingCancel}
        />
        <Redirect from="/billing" to="/billing/checkout" />
      </Switch>
      <ToastContainer position="bottom-center" />
    </div>
  )
}
