import { getAuthenticatedUser } from '../common/auth'
import { jwtDecode } from 'jwt-decode'

type Props = {
  serviceName: string
  subscriptionTier: string
}

export async function verifySubscription(serviceName: string, subscriptionTier: string): Promise<boolean | void> {

  const currentUser = await getAuthenticatedUser()
  if (!currentUser?.session?.access_token) {
    console.warn('No current access_token found!')
    return
  }

  const jwt = jwtDecode<{ user_role: string }>(currentUser.session.access_token)
  const userRole = jwt.user_role

  // console.log(jwt)

  // const isValid = verifyStripeSubscription(serviceName, subscriptionTier)
  // return isValid

  return true
}
