import { supabase } from '../lib/supabase'
import { Database } from '../types'

const HEALTHCARE_VISITS_TABLE = 'healthcare_visits'
const HEALTHCARE_VISIT_DOCTORS_TABLE = 'healthcare_visit_doctors'
const HEALTHCARE_VISIT_MEDICATIONS_TABLE = 'healthcare_visit_medications'
const HEALTHCARE_VISIT_CONDITIONS_TABLE = 'healthcare_visit_conditions'

export async function getHealthcareVisits(profileId: string) {
  const { data } = await supabase
    .from(HEALTHCARE_VISITS_TABLE)
    .select(`*, 
      ${HEALTHCARE_VISIT_CONDITIONS_TABLE} (*),
      ${HEALTHCARE_VISIT_MEDICATIONS_TABLE} (*),
      ${HEALTHCARE_VISIT_DOCTORS_TABLE} (*)
    `)
    .throwOnError()
    .eq('profile_id', profileId)
  return data
}

export async function getHealthcareVisit(healthcareVisitId: string) {
  const { data } = await supabase
    .from(HEALTHCARE_VISITS_TABLE)
    .select(`*, 
      ${HEALTHCARE_VISIT_CONDITIONS_TABLE} (*),
      ${HEALTHCARE_VISIT_MEDICATIONS_TABLE} (*),
      ${HEALTHCARE_VISIT_DOCTORS_TABLE} (*)
    `)
    .throwOnError()
    .eq('id', healthcareVisitId)
    .single()

  return data
}

export type HealthcareVisit = Database["public"]["Tables"]["healthcare_visits"]["Row"]
export type HealthcareVisitDoctor = Database["public"]["Tables"]["healthcare_visit_doctors"]["Row"]
export type HealthcareVisitMedication = Database["public"]["Tables"]["healthcare_visit_medications"]["Row"]
export type HealthcareVisitCondition = Database["public"]["Tables"]["healthcare_visit_conditions"]["Row"]
export type HealthCareVisitFull = HealthcareVisit & {
  healthcare_visit_doctors: HealthcareVisitDoctor[]
  healthcare_visit_medications: HealthcareVisitMedication[]
  healthcare_visit_conditions: HealthcareVisitCondition[]
}