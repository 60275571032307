import { Button } from 'reactstrap'

// const urlParams = new URLSearchParams(window.location.search)
// const serviceName = urlParams.get('serviceName')
// const subscriptionTier = urlParams.get('subscriptionTier')
// const subscriptionUrl = urlParams.get('subscriptionUrl')

type Props = {
  serviceName: string
  subscriptionTier: string
  subscriptionUrl: string
}

export function SubscriptionUpgradeRequired({ serviceName, subscriptionTier, subscriptionUrl }: Props) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '200px 1rem',
      textAlign: 'center',
      backgroundColor: '#F8F9FA',
      color: '#333',

    }}>
      <h1
        style={{ fontSize: '1.9rem' }}
        className="mb-4"
      >
        Subscription Upgrade Required
      </h1>
      <p style={{ fontSize: '1rem' }} className="mb-4">You'll need to subscribe to the <span className="font-weight-bold">{serviceName}</span> <span className="font-weight-bold" style={{ color: '#B03B70' }}>{subscriptionTier}</span> plan to access this page.</p>
      <Button
        color="primary"
        href={subscriptionUrl || 'https://1yourhealth.com'}
      >
        Upgrade Subscription
      </Button>
    </div>
  )
}