export function RxMgmtModalTutorialComprehensive() {
  return (
    <div className="modal-body pt-0">
      {/* title is derived from the serviceName and subscriptionTier props passed to the HeaderWithTutorial component */}
      <p>
        Scroll over to the left column using your finger (mobile) or your mouse/pad
        (laptop). This will show you the YourHealth Life Comprehensive that you
        subscribed to; click or tap to the highlighted Comprehensive application.
        Before you begin using Medication Comprehensive, make sure that you have
        completed your Starter and Advanced application; it is included as part of
        your Comprehensive subscription.
      </p>
      <p>
        All the features and functionality in the <span className="font-weight-bold">Starter</span> and <span className="font-weight-bold">Advanced</span> Medication
        Management are available to use. The initial screen for Comprehensive looks
        very similar to the initial screen for Advanced.
      </p>
      <div className="mb-5" style={{ maxWidth: 600 }}>
        <img
          src="/tutorials/medication-management/comprehensive/page.jpg"
          alt="Medication Management Comprehensive Page"
          className="img-fluid shadow rounded-sm"
        />
      </div>
      <p>
        Comprehensive unlocks some exciting capabilities that you can access by
        selecting a medication. See the features on the bottom of the page.
      </p>
      <div className="mb-5" style={{ maxWidth: 600 }}>
        <img
          src="/tutorials/medication-management/comprehensive/details.jpg"
          alt="Medication Management Comprehensive Detailed View"
          className="img-fluid shadow rounded-sm"
        />
      </div>
      <p>
        In addition to the functionality, you unlocked in the Advanced tier –
        Alternative Medications and Recent Research – Comprehensive allows you to
        select a medication and click on Show Potential Contraindications button. It
        will look at all the medications that you have listed, do the research and
        indicate if there are potential contraindications between that medication and
        all the others you have listed. This can be very important, especially if you
        have been prescribed medications from different sources.
      </p>
      <p>
        The next button, Show Potential Side Effects, of a medication that you are
        taking. Hit the button and YourHealth does the research for you and indicates
        what are potential side effects you may experience while taking that selected
        medication.
      </p>
      <p>
        Remember to consult with your doctor any time you get information from
        YourHealth.
      </p>
    </div>
  )
}