export function RxMgmtModalTutorialStarter() {
  return (
    <div className="modal-body pt-0">
      {/* title is derived from the serviceName and subscriptionTier props passed to the HeaderWithTutorial component */}
      <p>
        Scroll over to the left column using your finger <span className="font-italic font-weight-bold">(mobile)</span> or your mouse/pad <span className="font-italic font-weight-bold">(laptop)</span>. This
        will show you the YourHealth Life application that you subscribed to; click or tap to the
        highlighted application tier (starter, advanced or comprehensive). Each will bring up the
        associated functionality screen.
      </p>
      <div className="mb-5" style={{ maxWidth: 400 }}>
        <img
          src="/tutorials/medication-management/starter/menu.jpg"
          alt="Medication Management Menu"
          className="img-fluid shadow rounded-sm"
        />
      </div>
      <p>
        This is what you will see.
      </p>
      <div className="mb-3" style={{ maxWidth: 600 }}>
        <img
          src="/tutorials/medication-management/starter/page.jpg"
          alt="Medication Management Starter Page"
          className="img-fluid shadow rounded-sm"
        />
      </div>
      <p>
        With <span className="font-weight-bold">Medication Management Starter</span>, you can add new medications and update
        information on existing medications. In the first screen you can add a new medication
        and list the Name, Frequency (how often to take it), the date it was first prescribed. The
        notes section is for you to add anything that might help, for example, “take this after
        eating” or “make sure you take this before bedtime” When you select add a new
        medication, the new medication will be automatically added to your Essential Care
        Card.
      </p>
      <p>
        You can also click on any of your current medications listed. A popup will be displayed,
        and you can add date prescribed and any notes you might have.
      </p>

      <p className="mt-4">
        Medication Management also offers a reminder to take your medications. Check the box that says <span className="font-weight-bold">Receive daily emailed medication reminders</span>. By doing that YourHealth Life will send you an email every day that will help you remember to take your medications.
      </p>
      <div className="mb-4" style={{ maxWidth: 600 }}>
        <img
          src="/tutorials/medication-management/starter/reminders.png"
          alt="Medication Management Reminders"
          className="img-fluid shadow rounded-sm"
        />
      </div>

      <p>
        If you are interested in additional capabilities and benefits in the Medication
        Management Advanced and Comprehensive solutions, go to our website, {' '}
        <a href="https://1yourhealth.com" target="_blank" rel="noopener noreferrer">https://1yourhealth.com</a> to learn more.
      </p>
      <p>
        Make sure that you speak with your doctor about the information that you gotten from
        YourHealth Life.
      </p>
    </div>
  )
}