import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import MedicalAdminHeader from '../../../components/Headers/MedicalAdminHeader'
import { getActiveSubscriptions } from '../../../supabase-api/active_subscriptions'
import { getCurrentUser } from '../../../supabase-api/current_user'
import { getHealthcareVisit } from '../../../supabase-api/healthcare_visits'
import { isSubscriptionTierAvailable } from '../../../utils/subscriptionTierAvailablityCheck'
import { SubscriptionUpgradeRequired } from '../../subscriptions/SubscriptionUpgradeRequired'
import { HcvDetails } from '../shared/HcvDetails'
import { HcvForm } from '../shared/HcvForm'

export default function HealthcareVisitsStarterDetails() {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { id: healthcareVisitId } = useParams<{ id: string }>()

  const { data: healthcareVisit } = useQuery({
    queryKey: ['healthcare_visits', healthcareVisitId],
    queryFn: () => getHealthcareVisit(healthcareVisitId),
  })

  const { data: currentUser, isLoading: isCurrentUserLoading } = useQuery({
    queryKey: ['current_user'],
    queryFn: () => getCurrentUser(),
  })

  const { data: activeSubscriptions, isLoading: isLoadingActiveSubscriptions } = useQuery({
    queryKey: ['user_subscriptions'],
    queryFn: () => getActiveSubscriptions(currentUser!.id),
    retry: false,
    enabled: !!currentUser
  })

  if (isLoadingActiveSubscriptions || !activeSubscriptions || isCurrentUserLoading || isLoadingActiveSubscriptions) {
    return 'Loading...'
  }
  const isTierAvailable = isSubscriptionTierAvailable(activeSubscriptions.map(sub => sub.products!.name), 'Healthcare Visits Starter')

  if (!isTierAvailable) {
    return <SubscriptionUpgradeRequired
      serviceName="Healthcare Visits"
      subscriptionTier="Starter"
      subscriptionUrl={process.env.REACT_APP_SUBSCRIPTION_SITE_URL || '#'}
    />
  }

  if (!healthcareVisit || isLoadingActiveSubscriptions || !activeSubscriptions || isCurrentUserLoading || isLoadingActiveSubscriptions) {
    return 'Loading...'
  }

  return (
    <>
      <MedicalAdminHeader name="Healthcare Visits" parentName="Starter" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            {!isEditing &&
              <HcvDetails
                healthcareVisit={healthcareVisit}
                subscriptionTier="starter"
                onEdit={() => setIsEditing(true)}
              />
            }
            {isEditing &&
              <HcvForm
                subscriptionTier="starter"
                formAction="update"
                defaultValues={healthcareVisit}
                onCancelEdit={() => setIsEditing(false)}
              />
            }
          </Col>
        </Row>
      </Container>
    </>
  )
}
