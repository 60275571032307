export function RxMgmtModalTutorialStarter() {
  return (
    <div className="modal-body pt-0">
      {/* title is derived from the serviceName and subscriptionTier props passed to the HeaderWithTutorial component */}
      <p>
        You begin by selecting the Healthcare Visits Starter in the left side navigation bar.
      </p>
      <p>
        You will see a page
      </p>
      <div className="mb-5" style={{ maxWidth: 600 }}>
        <img
          src="/tutorials/healthcare-visits/starter/hcv1.jpg"
          alt="Healthcare Visits Starter Form"
          className="img-fluid shadow rounded-sm"
        />
      </div>
      <p>Here you can create a new healthcare visit.</p>
      <p>Take the following steps</p>
      <ol style={{ lineHeight: 2 }}>
        <li>Name the visit, for example General Check Up or Surgery Follow Up.</li>
        <li>Fill in the date for the visit.</li>
        <li>
          From the drop-down menu select from the list of doctors already your YourHealth Essential App.
          <ol type="a">
            <li>
              If you need to add a doctor, click on the button Add Doctor. The
              following popup will appear.
              <div style={{ maxWidth: 600 }}>
                <img
                  src="/tutorials/healthcare-visits/starter/hcv2.jpg"
                  alt="Form to add a new doctor"
                  className="img-fluid shadow rounded-sm"
                />
              </div>
            </li>

            <li>
              Fill out as much information as you have and click save.
              This doctor will also automatically update your contracts in your <span className="font-weight-bold">Essential</span> Application.
            </li>
          </ol>
        </li>
        <li>
          The next section works the same. You can select from the drop-down menu that you have already listed in your Essential Care Card.
          <ol type="a">
            <li>
              If you need to add a new condition, click on Add Condition. The following drop-down menu will appear:
              <div style={{ maxWidth: 600 }}>
                <img
                  src="/tutorials/healthcare-visits/starter/hcv3.jpg"
                  alt="Form to add a new condition"
                  className="img-fluid shadow rounded-sm"
                />
              </div>
            </li>
          </ol>
        </li>
        <li>
          <p>Prescriptions works the same as Conditions. All the medications that you have listed in your Essential Application will automatically be listed here. You can delist an application that you don&apos;t want for this visit by clicking the X next to the medication. The medication that you delete here will still be part of your Essential Application and can be accessed at any time.</p>
          <p>
            With both Prescriptions and ConditionsConditions, you can list as many or as few that you think is appropriate for this healthcare visit. If you want to remove a prescription or conditions, click on the x next to the name. Only one doctor can be listed for an individual healthcare visit.
          </p>
        </li>
        <li>
          In the notes section, you can add any information that you wish. This might include questions for your doctor or reminders on pre-visit preparations.
        </li>
        <li>
          Click save and your new healthcare visit is ready for you. If you did not fill out a required field the, you will see an indicator of what you need to do.
        </li>
      </ol>
      <p>
        Below creating a new healthcare visit is all the previous visits you have created. You can view them by selecting and clicking on it.
      </p>
    </div>
  )
}