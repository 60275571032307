import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import YourHealth from '../../../api';
import MedicalAdminHeader from "../../../components/Headers/MedicalAdminHeader.js";
import {
  LOAD_ALLERGIES
} from '../../../store/actions';
import { supabase } from '../../../lib/supabase';
import { notifyError, notifySuccess } from '../../../utils/notify';

const initialFormState = {
  name: '',
  description: '',
  notes: ''
}

const initialSelectedState = {
  id: '',
  description: '',
  notes: '',
  profileAllergiesId: ''
}

function Allergies({ profile, medical, dispatch }) {
  // Local States
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [addItem, setAddItem] = React.useState(false);
  const [activeNav, setActiveNav] = React.useState(1);
  const [formData, setFormData] = useState(initialFormState);

  // Update
  const [selectedData, setSelectedData] = useState(initialSelectedState);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  let history = useHistory();

  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  async function init() {
    setFormData({
      ...formData,
      'profileAllergiesId': profile.user.id
    });

    getAllergies(profile.user.id);
  }

  // Validate on form data change
  useEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true;

    // Check if valid - first + last name
    if (formData.name != "") {
      // console.error("Valid Form Entry!", formData);

      // Set save flag
      setAddItem(true);
    } else {
      // Set save flag
      setAddItem(false);
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [formData]);

  // Get Allergies
  async function getAllergies(profileId) {
    let { data, error } = await supabase
      .from('allergy')
      .select('*')
      .eq('profile_id', profileId)

    if (error) {
      console.error("Error getting allergies: ", error);
    }

    dispatch({
      type: LOAD_ALLERGIES,
      payload: data
    });
  }

  // Save Allergy
  async function saveAllergy() {
    if (!formData.name) return;

    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      if (window.posthog) {
        window.posthog.capture('Updated Data', {
          category: "Medical Information",
          label: "Allergies",
          $set: {
            email: profile.user.email,
          }
        });
      }
      
      // Mark change to profile
      YourHealth.api.email.tag({
        email: profile.user.email,
        list: "all_members",
        tag: "Updated_Profile"
      });
    }

    const { data, error } = await supabase
      .from('allergy')
      .insert({
        name: formData.name,
        description: formData.description,
        notes: formData.notes,
      })

    if (error) {
      console.error("Error creating allergy: ", error);
      notifyError("Error adding allergy");
      return
    }

    notifySuccess("Allergy added successfully");
    // await client.graphql({ query: createAllergy, variables: { input: formData } });

    // Clear form data
    setFormData({
      ...initialFormState,
      'profileAllergiesId': profile.user.id
    });

    // Reset flag
    setAddItem(false);

    await getAllergies(profile.user.id);
  }

  // Delete Allergy
  async function removeAllergy(allergyId) {

    const { error } = await supabase
      .from('allergy')
      .delete()
      .eq('id', allergyId)

    if (error) {
      console.error("Error deleting allergy: ", error);
      notifyError("Error deleting allergy");
      return
    }

    notifySuccess("Allergy deleted successfully");
    // await client.graphql({ query: deleteAllergy, variables: { input: { id: allergyId } } });

    await getAllergies(profile.user.id);
  }

  // Update Allergy
  async function modifyAllergy(allergy) {
    const { data, error } = await supabase
      .from('allergy')
      .update({
        name: allergy.name,
        description: allergy.description,
        notes: allergy.notes,
      })
      .eq('id', allergy.id)

    if (error) {
      console.error("Error updating allergy: ", error);
      notifyError("Error updating allergy");
      return
    }

    notifySuccess("Allergy updated successfully");

    // await client.graphql({ query: updateAllergy, variables: { input: allergy } });

    await getAllergies(profile.user.id);
  }

  return (
    <>
      <MedicalAdminHeader name="Allergies" parentName="Medical Information" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add New Allergy</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prescription-dose"
                          >
                            Name (Required)
                          </label>
                          <Input
                            id="input-prescription-dose"
                            placeholder=""
                            type="text"
                            value={formData.name}
                            onChange={e => setFormData({ ...formData, 'name': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prescription-frequency"
                          >
                            Reaction
                          </label>
                          <Input
                            id="input-prescription-frequency"
                            placeholder=""
                            type="text"
                            value={formData.description}
                            onChange={e => setFormData({ ...formData, 'description': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prescription-name"
                          >
                            Notes
                          </label>
                          <Input
                            id="input-prescription-name"
                            placeholder=""
                            type="text"
                            value={formData.notes}
                            onChange={e => setFormData({ ...formData, 'notes': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="secondary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          href=""
                          onClick={(e) => {
                            // Prevent default
                            e.preventDefault();

                            // Send to account
                            history.push('/admin/home');
                          }}
                          size="xl"
                        >
                          Return to Profile
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          onClick={saveAllergy}
                          size="xl"
                          disabled={!addItem}
                        >
                          Add Allergy
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Allergies</h3>
                  </Col>
                  <Col className="text-right" xs="6">

                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Reaction</th>
                    <th>Notes</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {medical.allergies.map((allergy, index) => (
                    <tr key={index}>
                      <td className="table-user">
                        <a
                          className="font-weight-bold"
                          href=""
                          onClick={(e) => {
                            // Stop default behavior
                            e.preventDefault();

                            // Set selected data
                            setSelectedData({
                              id: allergy.id,
                              name: allergy.name,
                              description: allergy.description,
                              notes: allergy.notes,
                              profileAllergiesId: formData.profileAllergiesId
                            });

                            // Open Modal
                            setdefaultModal(true);
                          }}
                        >
                          {allergy.name}
                        </a>
                      </td>
                      <td>
                        <span className="text-muted">
                          {allergy.description}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {allergy.notes}
                        </span>
                      </td>
                      <td className="table-actions">
                        <a
                          className="table-action"
                          href=""
                          id="tooltip564981685"
                          onClick={(e) => {
                            // Stop default behavior
                            e.preventDefault();

                            // Set selected data
                            setSelectedData({
                              id: allergy.id,
                              name: allergy.name,
                              description: allergy.description,
                              notes: allergy.notes,
                              profileAllergiesId: formData.profileAllergiesId
                            });

                            // Open Modal
                            setdefaultModal(true);
                          }}
                        >
                          <i className="fas fa-user-edit" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip564981685">
                          Edit Allergy
                        </UncontrolledTooltip>
                        <a
                          className="table-action table-action-delete"
                          href="#"
                          id="tooltip601065234"
                          onClick={() => removeAllergy(allergy.id)}
                        >
                          <i className="fas fa-trash" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip601065234">
                          Delete Allergy
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Allergy
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-prescription-dose"
                    >
                      Name
                    </label>
                    <Input
                      id="input-prescription-dose"
                      placeholder=""
                      type="text"
                      value={selectedData.name}
                      onChange={e => setSelectedData({ ...selectedData, 'name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-prescription-frequency"
                    >
                      Reaction
                    </label>
                    <Input
                      id="input-prescription-frequency"
                      placeholder=""
                      type="text"
                      value={selectedData.description}
                      onChange={e => setSelectedData({ ...selectedData, 'description': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-prescription-name"
                    >
                      Notes
                    </label>
                    <Input
                      id="input-prescription-name"
                      placeholder=""
                      type="text"
                      value={selectedData.notes}
                      onChange={e => setSelectedData({ ...selectedData, 'notes': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href="#"
            onClick={() => {
              modifyAllergy(selectedData);

              // Close modal
              setdefaultModal(false);
            }}
            size="xl"
          >
            Update Allergy
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedAllergies = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(Allergies);

export default ConnectedAllergies;
