import _ from 'lodash';
import { supabase } from '../../lib/supabase'
import { getAuthenticatedUser } from '../../common/auth';
import {
  INITIALIZE_PROFILE,
  CLEAR_PROFILE,
  SET_USER_ADMIN,
  SET_USER_BETA,
} from '../../store/actions';
import { jwtDecode } from 'jwt-decode'

const Profile = {
  logout: async function ({ profile, dispatch }, callback) {
    document.body.style = "background-color: #45296F;"

    try {
      const { error } = await supabase.auth.signOut({ scope: 'local' });
      if (error) {
        throw new Error(error.message)
      }
    } catch (error) {
      console.error(error.message)
      await supabase.auth.refreshSession()
      await supabase.auth.signOut()
    }

    window.posthog.reset()

    dispatch({
      type: CLEAR_PROFILE
    });

    if (callback) {
      callback();
    }
  },

  sync: async function ({ profile, dispatch }) {
    const currentUser = await getAuthenticatedUser();

    const jwt = jwtDecode(currentUser.session.access_token)
    const userRole = jwt.user_role

    if (userRole === 'admin') {
      dispatch({
        type: SET_USER_ADMIN
      })
    }

    if (userRole === 'beta') {
      dispatch({
        type: SET_USER_BETA
      })
    }

    const userInfo = currentUser.session?.user
    if (!userInfo) {
      return
    }

    // // Check if user has profile
    // if (!userInfo.attributes.hasOwnProperty('custom:profileId')) {
    //   // Initialize empty profile
    //   dispatch({
    //     type: INITIALIZE_PROFILE,
    //     payload: {
    //       username: userInfo.username,
    //       amplifyId: userInfo.id,
    //       email: userInfo.attributes.email
    //     }
    //   });

    //   // Short circuit
    //   return;
    // }

    let { data: profileData, error } = await supabase
      .from('profile')
      .select("*")
      .eq('id', userInfo.id)
      .single()

    if (error) {
      console.error("Error getting profile: ", error);
    }

    if (profileData) {

      // Check for admin rights
      // const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      // console.log(" - Groups: ", groups);

      dispatch({
        type: INITIALIZE_PROFILE,
        payload: {
          email: currentUser.session.user.email,
          ...profileData
        }
      });

      // // Set admin
      // if (groups && groups.includes('admin')) {
      //   // Set user admin
      // dispatch({
      //   type: SET_USER_ADMIN
      // });
      // }

      // Check billing
      // console.log("Checking Billing Status: ", profileData.billingId);
      // if (profileData.billingId == "-1") {
      //   console.log(" -- Bypassing Billing (10/1/2022)");

      //   // // Get query params
      //   // const queryParams = new URLSearchParams(window.location.href);
      //   // const checkoutSessionId = queryParams.get("checkout_session_id");
      //   // console.log("Checkout --> ", checkoutSessionId);
      //   //
      //   // // Setup billing
      //   // if(checkoutSessionId) {
      //   //   YourHealth.api.account.setup({
      //   //     environment: "dev",
      //   //     sessionId: checkoutSessionId,
      //   //     profile: apiData.data.getProfile,
      //   //     dispatch: dispatch
      //   //   });
      //   // }
      // } else {
      //   // Load billing data
      //   YourHealth.api.account.load({
      //     profile: profileData,
      //     dispatch: dispatch
      //   });
      // }
    } else {

      // Initialize empty profile
      dispatch({
        type: INITIALIZE_PROFILE,
        payload: {
          username: userInfo.username,
          amplifyId: userInfo.id,
          email: userInfo.email
        }
      });
    }
  }

  // sync: async function({ profile, dispatch }) {
  //   console.log("Syncing Profile: ", profile);
  //
  //   // Get user info
  //   const userInfo = await Auth.currentUserInfo();
  //   console.log(userInfo);
  //
  //   // Define filters
  //   const filter = {
  //     amplifyId: {
  //       eq: userInfo.id
  //     }
  //   };
  //
  //   // Create mutation
  //   const apiData = await API.graphql({
  //     query: listProfiles,
  //     variables: {
  //       filter: filter,
  //       limit: 1
  //     }
  //   });
  //
  //   // Update profile with data
  //   console.log(apiData.data, filter);
  //   if(apiData.data.listProfiles.items && apiData.data.listProfiles.items.length > 0) {
  //     console.log("Found Profile: ", apiData.data.listProfiles.items[0]);
  //
  //     // Dispatch update
  //     dispatch({
  //       type: INITIALIZE_PROFILE,
  //       payload: apiData.data.listProfiles.items[0]
  //     });
  //   } else {
  //     console.log("No profile found for: ", userInfo.id);
  //
  //     // Initialize empty profile
  //     dispatch({
  //       type: INITIALIZE_PROFILE,
  //       payload: {
  //         username: userInfo.username,
  //         amplifyId: userInfo.id,
  //         email: userInfo.attributes.email
  //       }
  //     });
  //   }
  // }
}

export default Profile;
